import React, { useState, useEffect, useRef, useCallback } from "react"; // eslint-disable-next-line
import {
  Matched as Candidate,
  Shortlist,
  Rejected,
  Selected,
  Recommended,
  Applied,
} from "./Candidatebox";
import "../../styles/candidate.scss";
import ChevronLeftTwoToneIcon from "@mui/icons-material/ChevronLeftTwoTone";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useHistory } from "react-router-dom";
import FreeBanner from "../Candidates/FreeTrialPrompt";
import Loading from "../Loading/Loading";
import { useErrorHandler } from "react-error-boundary";

function Candidateslist({
  companyid,
  jobid,
  mo_backend_url,
  backend_url,
  setjobcode,
  jobcode,
  maildata,
  setmaildata,
}) {
  const history = useHistory();
  const handleError = useErrorHandler();

  const observer = useRef();

  const [candidates, setcandidates] = useState([]);

  const [isloading, setisloading] = useState(false);

  const [hasmore, sethasmore] = useState(false);

  const [stopload, setstopload] = useState(true);

  const [update, setupdate] = useState(false);

  const [candidate_type, setcandidate_type] = useState(false);
  const [def, setdef] = useState(true);
  const [iswithd, setiswithd] = useState(false);
  const [reccomended, setreccomended] = useState(false);
  const [isapplied, setisapplied] = useState(false);

  const [loadmore, setloadmore] = useState(false);

  const [isfree, setisfree] = useState(false);
  const [category, setcategory] = useState("");
  const [status, setstatus] = useState("");

  const [count, setcount] = useState(0);

  const [page_no, setpage_no] = useState(0);

  const [nrml, setnrml] = useState([]);
  const [curcount, setcurcount] = useState(0);

  const [rejected, setrejected] = useState([]);
  const [selected, setselected] = useState([]);
  const [shortlisted, setshortlisted] = useState([]);
  const [askedToApply, setAskedToApply] = useState([]);
  const [reccomended_data, setreccomended_data] = useState([]);
  const [filteredreccomended, setfilteredreccomended] = useState([]);
  const [applied, setapplied] = useState([]);
  const [filteredapplied, setfilteredapplied] = useState([]);
  const [withdrawn, setwithdrawn] = useState([]);

  const [showpopup, setshowpopup] = useState(false);
  const [popup, setpopup] = useState(false);

  const lastelementref = useCallback(
    (node) => {
      if (isloading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((e) => {
        if (e[0].isIntersecting && hasmore && !isfree) {
          setloadmore(true);
          setpage_no((prev) => prev + 1);
        } else setloadmore(false);
      });
      if (node) observer.current.observe(node);
    },
    [isloading, hasmore],
  );

  useEffect(() => {
    if (companyid !== "") {
      fetch(backend_url + "/company/find", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          _id: companyid,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setcategory(data.result.category);
            setstatus(data.result.status);
            if (data.result.category === "Free") {
              setisfree(true);
            }
            fetch(`${backend_url}/job/find`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                _id: jobid,
              }),
            })
              .then((response) => response.json())
              .then((res) => {
                if (res.success) {
                  setmaildata({
                    company: data.result.company,
                    username: data.result.name,
                    jobtittle: res.result.jobTitle,
                    joblink: res.result.jobUrl,
                    city: res.result.city,
                    country: res.result.country,
                    level: res.result.careerLevel,
                    jobcode: res.result.jobCode,
                  });
                  setAskedToApply([...new Set(res.result.asked_to_apply)]);
                  setjobcode(res.result.jobCode);
                  localStorage.setItem(
                    "j_code",
                    JSON.stringify(res.result.jobCode),
                  );
                }
              });
          }
        })
        .catch((err) => handleError(err));
    }
  }, [backend_url, companyid, setmaildata, jobid, setjobcode]);

  useEffect(() => {
    const fetchdata = () => {
      fetch(mo_backend_url + "/momatch/" + jobid + "/" + page_no, {
        headers: { M_AUTH_KEY: JSON.parse(localStorage.getItem("j_token")) },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setcandidates((arr) => [
              ...[...arr, ...data.data]
                .reduce((map, obj) => map.set(obj._id, obj), new Map())
                .values(),
            ]);
            sethasmore(data.data.length > 0);
            setshortlisted([...new Set(data.shortlisted)]);
            setrejected([...new Set(data.reject)]);
            setselected([...new Set(data.hired)]);
            setapplied([...new Set(data.applied)]);
            setwithdrawn([...new Set(data.withdrawn)]);
            setreccomended_data([...new Set(data.recommend)]);
            setloadmore(false);
            setisloading(false);
            setstopload(false);
            setupdate(false);
          }
        })
        .catch(() => {
          handleError("momatch /page/fetch api throw's 502");
          setisloading(false);
        });
    };
    setisloading(true);
    if (jobid !== "" && status !== "" && status !== "In-Active") {
      fetch(mo_backend_url + "/momatch", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          jobid: jobid,
          timestamp: new Date(),
          page: page_no,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.exists || data.success) {
            fetchdata();
          } else {
            if (data.error === "data not found from momatch") {
              setcandidates([]);
              setisloading(false);
              setloadmore(false);
            } else handleError("momatch api issue");
          }
        })
        .catch((err) => {
          setisloading(false);
          handleError(err);
        });
    } else {
      if (jobid !== "" && status !== "") {
        fetchdata();
      }
    }
    // eslint-disable-next-line
  }, [
    backend_url,
    jobid,
    page_no,
    candidate_type,
    def,
    iswithd,
    reccomended,
    isapplied,
    status,
  ]);

  useEffect(() => {
    if (candidates.length > 0) {
      const arr = [];
      shortlisted.map((val) => arr.push(val._id));
      selected.map((val) => arr.push(val._id));
      rejected.map((val) => arr.push(val._id));
      arr.includes();
      const normalCandidatesData = [
        ...new Set(candidates.filter((val) => !arr.includes(val._id))),
      ];
      if (isfree) {
        setnrml(normalCandidatesData?.slice(-3));
      } else setnrml(normalCandidatesData);

      console.log(
        "Candidates: " + candidates.length,
        "Reccomended: " + reccomended_data.length,
        "Applied: " + applied.length,
        "Shortlisted: " + shortlisted.length,
        "Rejected: " + rejected.length,
        "Hired: " + selected.length,
        "Asked To Apply: " + askedToApply.length,
      );
      setcurcount(normalCandidatesData.length);
    }
  }, [candidates, selected, shortlisted, rejected]);

  useEffect(() => {
    if (applied.length > 0) {
      const arr = [];
      shortlisted.map((val) => arr.push(val._id));
      selected.map((val) => arr.push(val._id));
      rejected.map((val) => arr.push(val._id));
      arr.includes();
      setfilteredapplied([
        ...new Set(applied.filter((val) => !arr.includes(val))),
      ]);
      setcurcount(
        [...new Set(applied.filter((val) => !arr.includes(val)))].length,
      );
    }
  }, [applied, selected, shortlisted, rejected]);

  useEffect(() => {
    if (reccomended_data.length > 0) {
      const arr = [];
      shortlisted.map((val) => arr.push(val._id));
      selected.map((val) => arr.push(val._id));
      rejected.map((val) => arr.push(val._id));
      arr.includes();
      setfilteredreccomended([
        ...new Set(reccomended_data.filter((val) => !arr.includes(val))),
      ]);
      setcurcount(
        [...new Set(reccomended_data.filter((val) => !arr.includes(val)))]
          .length,
      );
    }
  }, [reccomended_data, selected, shortlisted, rejected]);

  useEffect(() => {
    if (hasmore && curcount <= 0) {
      setisloading(true);
      // setpage_no(nxt=>nxt+1);
    }
  }, [hasmore, curcount, candidates, shortlisted, rejected]);

  useEffect(() => {
    candidate_type && def
      ? setcount(selected.length)
      : def
      ? setcount(nrml.length)
      : candidate_type
      ? setcount(shortlisted.length)
      : reccomended
      ? setcount(filteredreccomended.length)
      : isapplied
      ? setcount(filteredapplied.length)
      : iswithd
      ? setcount(withdrawn.length)
      : setcount(rejected.length);
  }, [
    selected,
    def,
    candidate_type,
    reccomended,
    iswithd,
    isapplied,
    nrml,
    rejected,
    shortlisted,
    candidates,
    filteredreccomended,
    filteredapplied,
    withdrawn,
  ]);

  useEffect(() => {
    if (category === "Free") {
      appliedfunc();
    }
  }, [category]);

  useEffect(() => {
    if (nrml.length <= 0) {
      setpage_no((prev) => prev + 1);
      setloadmore(true);
    }
  }, [nrml]);

  const defaultfunc = () => {
    setcandidate_type(false);
    setdef(true);
    setiswithd(false);
    setreccomended(false);
    setisapplied(false);
    setshowpopup(false);
  };

  const reccomendedfunc = () => {
    setreccomended(true);
    setcandidate_type(false);
    setdef(false);
    setiswithd(false);
    setisapplied(false);
    if (isfree) {
      setpopup(true);
      setshowpopup(true);
    } else setshowpopup(false);
  };

  const appliedfunc = () => {
    setisapplied(true);
    setreccomended(false);
    setcandidate_type(false);
    setdef(false);
    setiswithd(false);
    setshowpopup(false);
  };

  const shortlist = () => {
    setcandidate_type(true);
    setdef(false);
    setiswithd(false);
    setreccomended(false);
    setisapplied(false);
    setshowpopup(false);
  };

  const reject = () => {
    setcandidate_type(false);
    setdef(false);
    setiswithd(false);
    setisapplied(false);
    setreccomended(false);
    setshowpopup(false);
  };

  const selectfun = () => {
    setcandidate_type(true);
    setdef(true);
    setisapplied(false);
    setiswithd(false);
    setreccomended(false);
    setshowpopup(false);
  };

  const selectwith = () => {
    setcandidate_type(false);
    setdef(false);
    setiswithd(true);
    setisapplied(false);
    setreccomended(false);
    setshowpopup(false);
  };

  return (
    <div style={{ background: "#eef2f5" }} className="flex-1 w-100 pa4-l pa3">
      <div className="w-80-l w-100 center flex flex-column">
        {/* job info */}
        <div className="flex">
          <ChevronLeftTwoToneIcon
            onClick={() => history.push("/Dashboard")}
            className="self-center dim pointer"
          />
          <div className="flex flex-column items-start">
            <p className="ma0 f5-l f6-m f7 pb2 tc">{`(${maildata.level}) ${maildata.jobtittle} - ${maildata.jobcode}`}</p>
            <p className="ma0 pl2 f7 gray tc">{`${maildata.city}, ${maildata.country}`}</p>
          </div>
        </div>
        <div className={`${isfree ? "" : "hide"}`}>
          <FreeBanner isTalentPoolPage={def && !candidate_type && !iswithd && !reccomended && !isapplied} />
        </div>
        {/* <Filter/> */}

        <div
          style={{ borderColor: "rgb(249, 246, 246)" }}
          className="z-4 flex self-start w-100 justify-start-l justify-center pt4 ml2-l pb1"
        >
          <Tooltip
            title={`Candidates who are pre-selected, interviewed and recommended by Moyyn Talent Managers appear here. This service is applicable only for Pay Per Hire jobs`}
          >
            <Button
              onClick={reccomendedfunc}
              variant="contained"
              className={`cbtn ${isfree ? "o-30" : ""} ${
                reccomended && !def && !candidate_type && !iswithd && !isapplied
                  ? "cbtn-active"
                  : ""
              }`}
            >
              Recommended
            </Button>
          </Tooltip>
          <Tooltip
            title={`Candidates from our Talent Pool who have been matched by our AI appear here`}
          >
            <Button
              onClick={defaultfunc}
              variant="contained"
              className={`cbtn ${isfree ? "o-70" : ""} ${
                def && !candidate_type && !iswithd && !reccomended && !isapplied
                  ? "cbtn-active"
                  : ""
              }`}
            >
              Talent Pool
            </Button>
          </Tooltip>
          <Tooltip
            title={`Candidates who directly applied to this job appear here`}
          >
            <Button
              onClick={appliedfunc}
              variant="contained"
              className={`cbtn ${
                isapplied && !reccomended && !def && !candidate_type && !iswithd
                  ? "cbtn-active"
                  : ""
              }`}
            >
              Applied
            </Button>
          </Tooltip>
          <Button
            onClick={shortlist}
            variant="contained"
            className={`cbtn ${
              candidate_type && !def && !iswithd && !reccomended && !isapplied
                ? "cbtn-active"
                : ""
            }`}
          >
            Shortlisted
          </Button>
          <Button
            onClick={selectfun}
            variant="contained"
            className={`cbtn ${
              candidate_type && def && !iswithd && !reccomended && !isapplied
                ? "cbtn-active"
                : ""
            }`}
          >
            Interview
          </Button>
          <Button
            onClick={reject}
            variant="contained"
            className={`cbtn ${
              !candidate_type && !def && !iswithd && !reccomended && !isapplied
                ? "cbtn-active"
                : ""
            }`}
          >
            Rejected
          </Button>
          {false && (
            <Button
              onClick={selectwith}
              variant="contained"
              className={`cbtn ${
                iswithd && !def && !candidate_type && !reccomended && !isapplied
                  ? "cbtn-active"
                  : ""
              }`}
            >
              Withdrawal
            </Button>
          )}
        </div>

        <p
          className={`${def ? "" : ""} ma0 gray mr2 mt2 mt0-l f6-l f7-m f7 tr`}
        >
          {"Candidates"}({count})
        </p>
        {(isloading && stopload) || (nrml.length <= 0 && hasmore) ? (
          <div className="flex items-center justify-center ma3 br2">
            <Loading text="A.I matchmaking in progress" />
          </div>
        ) : (
          <div className="w-100 flex center flex-column">
            <div
              className={`fixed overlay bottom-0 top-0 left-0 right-0 z-3 ${
                showpopup ? "active" : ""
              }`}
            ></div>
            {popup && (
              <div className="absolute z-5 bg-white popup-container flex justify-center items-center">
                <div
                  onClick={() => {
                    setpopup(false);
                    appliedfunc();
                  }}
                  className="absolute dim right-1 top-1 pointer"
                >
                  &times;
                </div>
                <p className="tc f4 fw6 gray">
                  Please Upgrade Your Account to access this feature
                </p>
              </div>
            )}
            {(candidates.length <= 0 &&
              selected.length <= 0 &&
              reccomended_data.length <= 0 &&
              withdrawn.length <= 0 &&
              applied.length <= 0) ||
            candidates === undefined ? (
              <div className="mt4 flex justify-center items-center">
                <p className="ma0 f3-l f4-m f6 gray tc">
                  No, Candidates matched yet. You will be notified once there
                  are new applicants
                </p>
              </div>
            ) : def && candidate_type ? (
              selected.length <= 0 ? (
                <div className="flex mt4 justify-center items-center">
                  <p className="ma0 f3-l f4-m f6 gray tc">
                    No candidates selected yet
                  </p>
                </div>
              ) : (
                selected.map((data, id) => {
                  let appliedtag = false;
                  let rectag = false;
                  let withtag = false;
                  let isAskedToApplyTag = false;

                  withdrawn.map((val) => {
                    if (val === data._id) {
                      withtag = true;
                    }
                    return withtag;
                  });
                  applied.map((val) => {
                    if (val === data._id) {
                      appliedtag = true;
                    }
                    return appliedtag;
                  });
                  reccomended_data.map((val) => {
                    if (val === data._id) {
                      rectag = true;
                    }
                    return rectag;
                  });
                  askedToApply.map((val) => {
                    if (val === data._id) {
                      isAskedToApplyTag = true;
                    }
                    return isAskedToApplyTag;
                  });
                  return (
                    <Selected
                      withtag={withtag}
                      rectag={rectag}
                      appliedtag={appliedtag}
                      isAskedToApplyTag={isAskedToApplyTag}
                      update={update}
                      setupdate={setupdate}
                      jobid={jobid}
                      setjobcode={setjobcode}
                      jobcode={jobcode}
                      mo_backend_url={mo_backend_url}
                      candidate={data}
                      k={id}
                      key={id}
                    />
                  );
                })
              )
            ) : def ? (
              nrml.length <= 0 ? (
                <div className="flex mt4 justify-center items-center">
                  <p className="ma0 f3-l f4-m f6 gray tc">
                    Sorry, No candidates were found!
                  </p>
                </div>
              ) : (
                <>
                  {nrml.map((data, id) => {
                    let appliedtag = false;
                    let rectag = false;
                    let withtag = false;
                    let isAskedToApplyTag = false;

                    withdrawn.map((val) => {
                      if (val === data._id) {
                        withtag = true;
                      }
                      return withtag;
                    });
                    applied.map((val) => {
                      if (val === data._id) {
                        appliedtag = true;
                      }
                      return appliedtag;
                    });
                    reccomended_data.map((val) => {
                      if (val === data._id) {
                        rectag = true;
                      }
                      return rectag;
                    });

                    askedToApply.map((val) => {
                      if (val === data._id) {
                        isAskedToApplyTag = true;
                      }
                      return isAskedToApplyTag;
                    });

                    if (nrml.length === id + 1) {
                      return (
                        <div key={id} ref={lastelementref}>
                          <Candidate
                            company={maildata.company}
                            job_name={maildata.jobtittle}
                            popup={isfree}
                            withtag={withtag}
                            rectag={rectag}
                            appliedtag={appliedtag}
                            shortlist={shortlist}
                            reject={reject}
                            update={update}
                            setupdate={setupdate}
                            jobid={jobid}
                            setjobcode={setjobcode}
                            jobcode={jobcode}
                            candidate={data}
                            mo_backend_url={mo_backend_url}
                            isAskedToApplyTag={isAskedToApplyTag}
                            key={id}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <Candidate
                          company={maildata.company}
                          job_name={maildata.jobtittle}
                          popup={isfree}
                          withtag={withtag}
                          rectag={rectag}
                          appliedtag={appliedtag}
                          isAskedToApplyTag={isAskedToApplyTag}
                          shortlist={shortlist}
                          reject={reject}
                          update={update}
                          setupdate={setupdate}
                          jobid={jobid}
                          setjobcode={setjobcode}
                          jobcode={jobcode}
                          candidate={data}
                          mo_backend_url={mo_backend_url}
                          key={id}
                        />
                      );
                    }
                  })}
                  {isfree && <FreeBanner isLoadingBanner={true} />}
                </>
              )
            ) : candidate_type ? (
              shortlisted.length <= 0 ? (
                <div className="flex mt4 justify-center items-center">
                  <p className="ma0 f3-l f4-m f6 gray tc">
                    No candidates shortlisted yet
                  </p>
                </div>
              ) : (
                shortlisted.map((data, id) => {
                  let appliedtag = false;
                  let rectag = false;
                  let withtag = false;
                  let isAskedToApplyTag = false;

                  withdrawn.map((val) => {
                    if (val === data._id) {
                      withtag = true;
                    }
                    return withtag;
                  });
                  applied.map((val) => {
                    if (val === data._id) {
                      appliedtag = true;
                    }
                    return appliedtag;
                  });
                  reccomended_data.map((val) => {
                    if (val === data._id) {
                      rectag = true;
                    }
                    return rectag;
                  });
                  askedToApply.map((val) => {
                    if (val === data._id) {
                      isAskedToApplyTag = true;
                    }
                    return isAskedToApplyTag;
                  });

                  return (
                    <Shortlist
                      company={maildata.company}
                      job_name={maildata.jobtittle}
                      withtag={withtag}
                      rectag={rectag}
                      appliedtag={appliedtag}
                      hiredbtn={selectfun}
                      reject={reject}
                      maildata={maildata}
                      update={update}
                      setupdate={setupdate}
                      jobid={jobid}
                      setjobcode={setjobcode}
                      jobcode={jobcode}
                      mo_backend_url={mo_backend_url}
                      candidate={data}
                      isAskedToApplyTag={isAskedToApplyTag}
                      key={id}
                    />
                  );
                })
              )
            ) : reccomended ? (
              filteredreccomended.length <= 0 ? (
                <div className="flex mt4 justify-center items-center">
                  <p className="ma0 f3-l f4-m f6 gray tc">
                    No candidates were recommended by moyyn yet
                  </p>
                </div>
              ) : (
                filteredreccomended.map((data, id) => {
                  let withtag = false;
                  withdrawn.map((val) => {
                    if (val === data._id) {
                      withtag = true;
                    }
                    return withtag;
                  });
                  return (
                    <Recommended
                      company={maildata.company}
                      job_name={maildata.jobtittle}
                      withtag={withtag}
                      popoup={isfree}
                      hiredbtn={selectfun}
                      reject={reject}
                      maildata={maildata}
                      update={update}
                      setupdate={setupdate}
                      jobid={jobid}
                      setjobcode={setjobcode}
                      jobcode={jobcode}
                      mo_backend_url={mo_backend_url}
                      candidate={data}
                      key={id}
                    />
                  );
                })
              )
            ) : // : iswithd ?
            //     withdrawn.length <= 0 ? <div className='flex mt4 justify-center items-center'><p className='ma0 f3-l f4-m f6 gray tc'>No candidates have withdrawn yet</p></div> : withdrawn.map((data, id) => <Withdrawn hiredbtn={selectfun} reject={reject} maildata={maildata} update={update} setupdate={setupdate} jobid={jobid} setjobcode={setjobcode} jobcode={jobcode} mo_backend_url={mo_backend_url} candidate={data} key={id} />)
            isapplied ? (
              filteredapplied.length <= 0 ? (
                <div className="flex mt4 justify-center items-center">
                  <p className="ma0 f3-l f4-m f6 gray tc">
                    No candidates have applied for this job
                  </p>
                </div>
              ) : (
                filteredapplied.map((data, id) => {
                  let withtag = false;
                  withdrawn.map((val) => {
                    if (val === data._id) {
                      withtag = true;
                    }
                    return withtag;
                  });
                  return (
                    <Applied
                      company={maildata.company}
                      job_name={maildata.jobtittle}
                      withtag={withtag}
                      hiredbtn={selectfun}
                      reject={reject}
                      maildata={maildata}
                      update={update}
                      setupdate={setupdate}
                      jobid={jobid}
                      setjobcode={setjobcode}
                      jobcode={jobcode}
                      mo_backend_url={mo_backend_url}
                      candidate={data}
                      key={id}
                    />
                  );
                })
              )
            ) : rejected.length <= 0 ? (
              <div className="flex mt4 justify-center items-center">
                <p className="ma0 f3-l f4-m f6 gray tc">
                  No candidates rejected yet
                </p>
              </div>
            ) : (
              rejected.map((data, id) => {
                let appliedtag = false;
                let rectag = false;
                let withtag = false;
                let isAskedToApplyTag = false;

                withdrawn.map((val) => {
                  if (val === data._id) {
                    withtag = true;
                  }
                  return withtag;
                });
                applied.map((val) => {
                  if (val === data._id) {
                    appliedtag = true;
                  }
                  return appliedtag;
                });
                reccomended_data.map((val) => {
                  if (val === data._id) {
                    rectag = true;
                  }
                  return rectag;
                });
                askedToApply.map((val) => {
                  if (val === data._id) {
                    isAskedToApplyTag = true;
                  }
                  return isAskedToApplyTag;
                });
                return (
                  <Rejected
                    company={maildata.company}
                    job_name={maildata.jobtittle}
                    withtag={withtag}
                    rectag={rectag}
                    appliedtag={appliedtag}
                    isAskedToApplyTag={isAskedToApplyTag}
                    shortlist={shortlist}
                    update={update}
                    setupdate={setupdate}
                    jobid={jobid}
                    setjobcode={setjobcode}
                    jobcode={jobcode}
                    mo_backend_url={mo_backend_url}
                    candidate={data}
                    key={id}
                  />
                );
              })
            )}
          </div>
        )}
      </div>
      {loadmore && (
        <div className="flex justify-center items-center">
          <Loading text="" />
        </div>
      )}
    </div>
  );
}

export default Candidateslist;
