import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import "tachyons";
import "../../styles/login.scss";
import { useHistory } from "react-router-dom";
import { Link } from "@mui/material";
import { useErrorHandler } from "react-error-boundary";

function Login({ setlog, backend_url, setcompanydata, setToken }) {
  const [username, setusername] = useState("");
  const [pass, setpass] = useState("");
  const [empty, setempty] = useState(false);
  const [wrong, setwrong] = useState(false);
  const [clicked, setclicked] = useState(false);
  const [notapproved, setnotapproved] = useState(false);

  const history = useHistory();
  const handleError = useErrorHandler()

  useEffect(() => {
    if (clicked && !empty) {
      fetch(backend_url + "/company/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: username,
          password: pass,
          timestamp: new Date(),
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success === true) {
            if (
              data.result.status === "Pending" &&
              data.result.category === "None"
            ) {
              setnotapproved(true);
            } else {
              setlog(true);
              setwrong(false);
              setnotapproved(false);
              setcompanydata(data.result._id);
              localStorage.setItem("c_id", JSON.stringify(data.result._id));
              localStorage.setItem("loggedin", JSON.stringify(true));
              setToken(data.token ?? null);
              localStorage.setItem("j_token", JSON.stringify(data.token || null));
              history.push("/dashboard");
            }
          } else {
            setpass("");
            setwrong(true);
          }
        })
        .catch((err) => {
          setpass("");
          setwrong(true);
          handleError(err);
        });
      setclicked(false);
    } // eslint-disable-next-line
  }, [clicked, empty]);

  const auth = () => {
    setclicked(true);
    if (username === "" || pass === "") {
      setempty(true);
    } else {
      setempty(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      auth();
    }
  };

  return (
    <div className="flex w-100 flex-column items-center justify-center vh-75">
      <p className="gray tc f3-l f3-m f4">Moyyn Company Login</p>
      <div className="c-shadow bg-white pa5-l pa5-m pa4 w-50-l w-60-m w-90">
        <div className="mb4 center w-80-l w-80-m inp-log w-100">
          <TextField
            name="login_id"
            label="Email"
            type="text"
            value={username.toLowerCase()}
            onKeyDown={handleKeyDown}
            onChange={(e) => setusername(e.target.value)}
            variant="outlined"
            className={`w-100`}
          />
        </div>
        <div className="inp-log mb3 center w-80-l w-80-m w-100">
          <TextField
            name="passwd"
            label="Password"
            type="password"
            value={pass}
            onChange={(e) => setpass(e.target.value)}
            onKeyDown={handleKeyDown}
            variant="outlined"
            className={`w-100`}
          />
        </div>
        <div className="w-80-l w-80-m w-100 f8 mb3 center">
          By logging In, I agree to the{" "}
          <Link
            href="https://moyyn.com/terms-and-conditions-2/"
            rel="noopener"
            target="_blank"
            style={{ cursor: "pointer", color: "#6EB6FF" }}
          >
            Terms of Use
          </Link>{" "}
          and{" "}
          <Link
            href="https://moyyn.com/privacy/"
            rel="noopener"
            target="_blank"
            style={{ cursor: "pointer", color: "#6EB6FF" }}
          >
            Privacy Policy
          </Link>
          .{" "}
        </div>
        <div
          onClick={() => history.push("/forgot")}
          className="mb3 center gray f6-l f6-m f7 pointer dim w-80-l w-80-m w-100"
        >
          Forgot password ?
        </div> 
        <div className="flex justify-center">
          <Button
            onClick={auth}
            className="dim w-30-l w-35-m w-50"
            style={{ backgroundColor: "#6EB6FF", color: "white" }}
            variant="contained"
          >
            Login
          </Button>
        </div>
        <div className={`${wrong || empty || notapproved ? "" : "hide"} mt4`}>
          <Alert severity="error">
            {wrong
              ? "Wrong credentials, Try Again!"
              : empty
              ? "Field cannot be empty!"
              : notapproved
              ? "You're not yet approved, Try again after some time"
              : ""}
          </Alert>
        </div>
      </div>
    </div>
  );
}

export default Login;
