import React, { useState, useEffect } from "react";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Commentbox from "../Commentbox";
import ResumePreview from "../ResumePreview";
import { useMediaQuery } from "react-responsive";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import Tooltip from "@mui/material/Tooltip";
import { cv_prefix, cv_prefix_gr } from "../../../util/helpers/helper-methods";

export const Rejected = React.memo(
  ({
    appliedtag,
    rectag,
    mo_backend_url,
    candidate,
    jobid,
    jobcode,
    setupdate,
    shortlist,
    k,
    withtag,
    job_name,
    company,
    isAskedToApplyTag,
  }) => {
    const [isPreviewOpen, setisPreviewOpen] = useState(false);
    const ismobile = useMediaQuery({ query: `(max-width: 580px)` });

    const [lang, setlang] = useState([]);

    const [candidateid, setcandidateid] = useState(candidate._id);

    const [candidatesdata, setcandidatesdata] = useState({});
    const [cv, setcv] = useState("");

    const [shortclicked, setshortclicked] = useState(false);

    const [job_code] = useState(jobcode);

    const [userdeleted, setuserdeleted] = useState(false);

    const [dim, setdim] = useState(false);

    const [isloading, setisloading] = useState(false);

    useEffect(() => {
      if (candidateid !== "") {
        setisloading(true);
        fetch(mo_backend_url + "/momatch/" + candidateid)
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {
              setcandidatesdata(res.data[0]);
              if (lang.length <= 0) {
                res.data[0].languages.map((val) =>
                  setlang((lang) => [
                    ...lang,
                    val.language + "( " + val.level + " )",
                  ]),
                );
              }
              setcandidateid(res.data[0]._id);
              setisloading(false);
            } else {
              if (!res.success && res.msg === "not found") {
                setisloading(false);
                setuserdeleted(true);
              }
            }
          });
      }
    }, [candidateid, mo_backend_url, lang]);

    useEffect(() => {
      if (shortclicked && job_code !== "" && job_code !== undefined) {
        setdim(true);
        fetch(mo_backend_url + "/candidates/shortlist", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            timestamp: new Date(),
            job_id: jobid,
            job_code: job_code,
            candidate_id: candidateid,
            candidate_name:
              candidatesdata.firstName + " " + candidatesdata.lastName,
            candidate_email: candidatesdata.email,
            job_name: job_name,
            company: company,
          }),
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {
              // setshortclicked(false);
              setupdate(true);
              // shortlist();
            }
          });
        // setTimeout(()=>setdim(false),5300);
      }
    }, [
      jobid,
      shortclicked,
      mo_backend_url,
      job_code,
      candidateid,
      setupdate,
      shortlist,
      candidatesdata,
      job_name,
    ]);

    useEffect(() => {
      if (candidatesdata.cv === undefined) return;
      setcv(
        `${candidatesdata.cv.english ? cv_prefix : cv_prefix_gr}${
          candidatesdata.cv.filename
        }`,
      );
    }, [candidatesdata]);

    return (
      <>
        {userdeleted ? (
          <div
            key={k}
            style={{ borderRadius: "6px", borderLeft: "3px solid red" }}
            className={`w-100 relative ma1 dib ch bg-white pa3 mt3 br3}`}
          >
            <div>
              <p>Candidate has deleted their profile</p>
            </div>
            <p
              className={`absolute ma0 fw6 pl1 pr1 br2 f8`}
              style={{
                border: `1px solid ${"red"}`,
                color: "red",
                top: "0.5rem",
                left: `${dim ? "4.6rem" : "1rem"}`,
              }}
            >
              {"Deleted"}
            </p>
          </div>
        ) : isloading ? (
          <div
            key={k}
            style={{ borderRadius: "6px", borderLeft: "3px solid #6EB6FF" }}
            className={`w-100 flex justify-between ma1 dib ch bg-white pa3 mt3 br3}`}
          >
            <div className="flex flex-column w-20">
              <div className="skeleton skeleton-text mt3"></div>
              <div className="skeleton skeleton-text "></div>
              <div className="skeleton skeleton-text "></div>
            </div>
            <div className="flex flex-column ml6 mr5 w-10">
              <div className="skeleton skeleton-text mt3"></div>
              <div className="skeleton skeleton-text "></div>
              <div className="skeleton skeleton-text "></div>
            </div>
            <div className="flex-1 flex justify-around items-center">
              <div
                onClick={() => {}}
                className="ico mb2 pointer flex flex-column items-center self-center"
              >
                <div style={{ color: "gray" }}>
                  <DescriptionOutlinedIcon />
                </div>
                <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">View CV</p>
              </div>
              <Commentbox
                candidateid={candidateid}
                jobid={jobid}
                mo_backend_url={mo_backend_url}
              />
            </div>
            <div className="h-100 flex-1 w-25-l w-40-m w-30 pr4 mb2 mt3-l">
              <div className="flex justify-center flex-row-l flex-row-m flex-column justify-center items-center mt1">
                <button
                  onClick={() => {}}
                  style={{ color: "#6EB6FF" }}
                  className="bg-white pointer ml2-l ml2-m ml3 c-shadow w-80-l w-50-m w3 h2-l mt2  mt0-l mt0-m bn link dim br2 ph3 pv2-l pv1 dib"
                >
                  <div className="btnic flex f9-mo f8-m f7-l f8-m  items-center justify-center">
                    <KeyboardBackspaceOutlinedIcon />
                    <span className="ml2">
                      Shortlist{ismobile ? "" : " Again"}
                    </span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div
            key={k}
            style={{
              borderRadius: "6px",
              borderLeft:
                dim || shortclicked
                  ? "16px solid #078307"
                  : "3px solid #6EB6FF",
            }}
            className={`${
              dim ? "" : "dn"
            } w-100 relative ma1 dib ch bg-white pa3 mt3 br3}`}
          >
            <ResumePreview
              open={isPreviewOpen}
              close={() => setisPreviewOpen(!isPreviewOpen)}
              src={`${cv}`}
            />
            <div className="flex justify-between items-start mt2-l mt0">
              <div className="flex-1 flex items-center">
                <div className="flex name_candidatebox flex-column">
                  <p className="truncate ma0 f4-l f5-m f7">
                    {candidatesdata.firstName + " " + candidatesdata.lastName}
                  </p>
                  <p
                    style={{ color: "#6EB6FF" }}
                    className="ma0 gray f7-l mt1 f7-m f8-mo"
                  >
                    {candidatesdata.careerLevel}
                  </p>
                  <Tooltip title={lang.join(", ")}>
                    <p className="truncate ma0 mt2_2 gray f8 f9-m f10-mo mt1">
                      {lang.join(", ")}
                    </p>
                  </Tooltip>
                </div>
              </div>
              <div
                className={`flex-1 flex flex-column items-start ${
                  ismobile ? "hide" : ""
                }`}
              >
                {candidatesdata.skills && (
                  <p
                    style={{ textTransform: "capitalize" }}
                    className="truncate ma0 flex-1 mb3 gray f7 f8-m f8-mo"
                  >
                    Skills:{" "}
                    {`${candidatesdata.skills
                      .filter((_, i) => i < 3)
                      .join(", ")}`}
                  </p>
                )}
                {candidatesdata.industries && (
                  <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo truncate">{`Industry: ${`${candidatesdata.industries
                    .filter((_, i) => i < 2)
                    .join(", ")}`}`}</p>
                )}
                {(candidatesdata.city || candidatesdata.country) && (
                  <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo truncate">{`Location: ${`${candidatesdata.city}, ${candidatesdata.country}`}`}</p>
                )}
              </div>
              <div className="flex-1 flex justify-around items-center">
                <div
                  onClick={() =>
                    navigator.userAgent.indexOf("Chrome") !== -1 &&
                    navigator.vendor.indexOf("Google Inc") !== -1
                      ? window.open(
                          `${cv}`,
                          "_blank",
                          "toolbar=no,scrollbars=yes,resizable=no,top=500,left=500,width=650,height=800",
                        )
                      : setisPreviewOpen((prev) => !prev)
                  }
                  className="ico mb2 pointer flex flex-column items-center self-center"
                >
                  <div style={{ color: "gray" }}>
                    <DescriptionOutlinedIcon />
                  </div>
                  <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">View CV</p>
                </div>
                <Commentbox
                  candidateid={candidateid}
                  jobid={jobid}
                  mo_backend_url={mo_backend_url}
                />
              </div>
              <div className="flex-1 h-100 w-25-l w-40-m w-30 pr4 mb2 mt3-l">
                <div className="flex justify-center flex-row-l flex-row-m flex-column justify-center items-center mt1">
                  <button
                    onClick={() => setshortclicked(true)}
                    style={{ color: "#6EB6FF" }}
                    className="bg-white pointer ml2-l ml2-m ml3 c-shadow w-80-l w-50-m w3 h2-l mt2  mt0-l mt0-m bn link dim br2 ph3 pv2-l pv1 dib"
                  >
                    <div className="btnic flex f9-mo f8-m f7-l f8-m  items-center justify-center">
                      <KeyboardBackspaceOutlinedIcon />
                      <span className="ml2">
                        Shortlist{ismobile ? "" : " Again"}
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <p
              className={`${
                dim ? "absolute left-1 ma0 fw6 pl1 pr1 br2 f8" : "dn"
              }`}
              style={{
                border: `1px solid ${
                  dim || shortclicked ? "green" : "#6EB6FF"
                }`,
                color: dim || shortclicked ? "green" : "#6EB6FF",
                top: "0.5rem",
              }}
            >
              {dim || shortclicked
                ? "Shortlisted Again"
                : "Recommended by Moyyn"}
            </p>
            <p
              className={`absolute ma0 fw6 pl1 pr1 br2 f8`}
              style={{
                border: `1px solid ${
                  withtag ? "red" : appliedtag || rectag ? "green" : "#6EB6FF"
                }`,
                color: `${
                  withtag ? "red" : appliedtag || rectag ? "green" : "#6EB6FF"
                }`,
                top: "0.5rem",
                left: `${dim ? (rectag ? "7.2rem" : "5.2rem") : "1rem"}`,
              }}
            >
              {withtag
                ? "Withdrawn"
                : appliedtag
                ? "Applied"
                : rectag
                ? "Recommended by Moyyn"
                : isAskedToApplyTag
                ? "Asked To Apply"
                : "Talent Pool"}
                </p>
                <p className="absolute top-1 right-1 ma0 br2 gray f8 f9-m f10-mo">
              Registered on{" "}
              {new Date(candidatesdata.createdAt).toLocaleDateString()}
            </p>
          </div>
        )}
      </>
    );
  },
);

export default Rejected;
