import React from "react";
import "./styles/index.css";
import App from "./Containers/App";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./Components/Error/ErrorHandler";
import { myErrorHandler } from "./util/helpers/helper-methods";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
    <App />
  </ErrorBoundary>
);
