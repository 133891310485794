import * as Yup from "yup";
import { string, ref } from "yup";

export const SignupEditformvalidation = Yup.object().shape({
  company: Yup.string()
    .trim()
    .required("Required"),
  name: Yup.string()
    .trim()
    .required("Required")
    .matches(/^[a-z A-Z]+$/, "Invalid name "), // eslint-disable-line
  website: Yup.string()
    .trim()
    .required("Required")
    .matches(
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      "Not a valid link",
    ), // eslint-disable-line
  company_privacy_policy: Yup.string()
    .trim()
    .matches(
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      "Not a valid link",
    ), // eslint-disable-line
  location: Yup.string().trim().required("Required"),
  email: Yup.string()
    .trim()
    .email("Not a valid email address")
    .required("Required"),
  phone: Yup.string()
    .required("Required")
    .matches(
      /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}\d$/,
      "Invalid number",
    ), //eslint-disable-line
});

export const Signupformvalidation = Yup.object().shape({
  company: Yup.string()
    .trim()
    .required("Required")
    .matches(/^[a-z A-Z]+$/, "Invalid company name"), // eslint-disable-line
  name: Yup.string()
    .trim()
    .required("Required")
    .matches(/^[a-z A-Z]+$/, "Invalid name "), // eslint-disable-line
  website: Yup.string()
    .trim()
    .required("Required")
    .matches(
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      "Not a valid link",
    ), // eslint-disable-line
  location: Yup.string().trim().required("Required"),
  email: Yup.string()
    .trim()
    .email("Not a valid email address")
    .required("Required"),
  password: Yup.string()
    .trim()
    .required("Required")
    .min(8, "Too short - minimum 8 characters required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
      "Password Must contain number & uppercase letters",
    ), //eslint-disable-line
  phone: Yup.string()
    .required("Required")
    .matches(
      /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}\d$/,
      "Invalid number",
  ), //eslint-disable-line
  modelType: Yup.string()
  .required("Required"),
  getToKnow: Yup.string()
  .required("Required"),
  howManyJobs: Yup.number()
  .typeError("Not a valid number")
  .positive("Can't be a Negative value")
  .required("Required"),
});

export const Postjobstep1formvalidation = Yup.object().shape({
  jobTitle: Yup.string().trim().required("Required"),
  description: Yup.string().trim().required("Required"),
  requirements: Yup.string().trim().required("Required"),
  Industries: Yup.array().required("Required"),
  Date: Yup.string(),
  flexibleDate: Yup.string(),
  currency: Yup.string(),
  relevantJobTitle: Yup.array().required("Required"),
  jobType: Yup.string().required("Required"),
  from: Yup.number()
    .typeError("Not a valid amount")
    .positive("Can't be a Negative value"),
  to: Yup.number()
    .typeError("Not a valid amount")
    .positive("Can't be a Negative value"),
}).test({
  name: "atLeastOneDate",
  message: "At least one of Date or flexibleDate is required",
  test: function (values) {
    const { Date, flexibleDate } = values;
    return Date || flexibleDate;
  },
});;

export const Postjobstep2formvalidation = Yup.object().shape({
  country: Yup.string().trim().required("Required"),
  state: Yup.string().trim().required("Required"),
  city: Yup.string().trim().required("Required"),
  postcode: Yup.string().trim().required("Required"),
  careerLevel: Yup.string().trim().required("Required"),
  Skills: Yup.array().required("Required"),
  skills_secondary: Yup.array(),
  jobCategory: Yup.string().trim().required("Required"),
  workExperience: Yup.array().required("Required"),
  Languages: Yup.array().required("*Required field."),
  otherCountries: Yup.string().trim().required("Required"),
});

export const careerPageFormValidation = Yup.object().shape({
  about: string().trim(),
  benefits: string().trim(),
  workCulture: string().trim(),
});

export const Postjobstep3formvalidation = Yup.object().shape({
  credits: Yup.string().trim().required("Required"),
});

export const ResetPass = Yup.object().shape({
  otp: string("*Please enter a valid one time password")
    .trim()
    .required("*Required"),
  password: string("*Please enter a valid password.")
    .trim()
    .required("Required")
    .min(8, "Too short - minimum 8 characters reuired")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
      "Password Must contain number & uppercase letters",
    ),
  confirmpassword: string("*Please confirm the password.")
    .trim()
    .required("*Please confirm the password.")
    .oneOf([ref("password"), null], "Passwords must match"),
});
