import React, { useState, useEffect } from "react";
import Credits from "../Settings/Billing/Credits";
import { Formik } from "formik";
import { Alert, MenuItem, Select } from "@mui/material";
import Loading from "../Loading/Loading";

export const creditsEuroMap = {
  1: 2,
  50: 40,
  100: 60,
  150: 80,
  200: 100,
};

function CreditManagement({ companyid, momatch_backend_url }) {
  const [credits, setCredits] = useState(0);
  const [balance, setBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isPending, setIsPending] = useState(false);

  const handleSubmit = async () => {
    if (!credits) return;
    const data = await fetch(momatch_backend_url + "/credit", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        company_id: companyid,
        value: parseInt(credits),
        email: localStorage.getItem("email") || "",
      }),
    });
    const creditsResponse = await data.json();
    if (creditsResponse.status === "PENDING") {
      setIsPending(true);
    }
  };

  useEffect(() => {
    if (!companyid) return;
    setIsLoading(true);
    fetch(`${momatch_backend_url}/credit/history`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        company_id: companyid,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setBalance(data.credits);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  }, [companyid]);

  if (isLoading) {
    return (
      <React.Fragment>
        <div className="flex w-100 items-center justify-center bg-white ma3 br2 vh-75">
          <Loading text="Loading credits history" />
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className="w-80 flex center flex-column mt5 mb5 bg-white br4 pa3">
      <h2>Credits Dashboard</h2>
      <div>
        <div className="gray f4-l f5-m f6 ">
          Credit Balance:{" "}
          <span className="b" style={{ color: "#6EB6FF" }}>
            {balance ?? "Not available"}
          </span>
        </div>
        {isPending && <div className="mt2">
          <Alert severity="success">
            {credits} Credits will be added once approved
      </Alert>
        </div>}
        <h4 className="gray f5-l f6-m f7 ">Request more credits:</h4>
        <div>
          <Formik
            initialValues={{
              candidates: 0,
            }}
            validationSchema={{}}
            onSubmit={() => handleSubmit()}
          >
            {(formik) => (
              <form
                className="flex items-center"
                onSubmit={formik.handleSubmit}
              >
                <Select
                  name="credits"
                  style={{
                    backgroundColor: "#f7f7f7",
                  }}
                  placeholder="credits"
                  onChange={(e) => {
                    setCredits(parseInt(e.target.value));
                  }}
                  className="w-30 h2"
                >
                  {
                    <MenuItem key={`cred--custom-${1}`} value={1}>
                      {1} candidates
                    </MenuItem>
                  }
                  {Array.from({ length: 4 }).map((_, idx) => {
                    const value = (idx + 1) * 50;
                    return (
                      <MenuItem key={`cred-${idx}`} value={value}>
                        {value} candidates
                      </MenuItem>
                    );
                  })}
                </Select>
                <button
                  type="submit"
                  style={{ background: "#6EB6FF", height: "37px" }}
                  className="mh2 pointer fw6 f7 f6-l bn link dim br1 ph3 pv1 ph4-m pv2-m  pv2-l dib white bg-white"
                  onClick={handleSubmit}
                >
                  Buy Credits
                </button>
              </form>
            )}
          </Formik>
          <div className="text-secondary font-semibold mt2">
            {credits ?? 1} credits = {credits ?? 1} candidates (&euro;{" "}
            {creditsEuroMap?.[credits] ?? 0})
          </div>
        </div>
      </div>
      <h4 className="gray f5-l f6-m f7">Credits history:</h4>
      <Credits companyId={companyid} momatchBackendUrl={momatch_backend_url} />
    </div>
  );
}

export default CreditManagement;
