import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  Signupformvalidation,
  SignupEditformvalidation,
} from "../../util/validation/form-validation";
import "tachyons";
import MuiAlert from "@mui/material/Alert";
import { Snackbar, Link } from "@mui/material";
import { useHistory } from "react-router-dom";
import RadioButtonsGroup from "../PostJob/Formelements/RadioOptions";
import { jobModelType } from "../../util/data/static-data";
import { useErrorHandler } from "react-error-boundary";

function SignUpForm({
  backend_url,
  companyid,
  setregistered,
  signupforminitialvalues,
  editcompany,
}) {
  const history = useHistory();
  const handleError = useErrorHandler()


  const [email, setemail] = useState("");
  const [stopmail, setstopmail] = useState(false);
  const [err, seterr] = useState({
    is: false,
    msg: "",
  });

  useEffect(() => {
    if ( !stopmail || !email  || email?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g) === null ) return;
    const timer = setTimeout(() => {
      fetch(backend_url + "/company/validateEmail", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email?.toLowerCase(),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setstopmail(false);
            if (!data.result.Availability) {
              seterr({
                is: true,
                msg: "Account already exist's with this email",
              });
            } else {
              seterr({
                is: false,
                msg: "",
              });
            }
          }
        })
        .catch((err) => {
          setstopmail(false);
          seterr({
            is: false,
            msg: "",
          });
          handleError(err)
        });
    }, 1000);
    return () => clearTimeout(timer);
  }, [email, backend_url, err, stopmail]);

  const [IsSnackbarOpen, setIsSnackbarOpen] = useState(true);

  return (
    <div> 
      <Formik
        initialValues={signupforminitialvalues}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          fetch(`${backend_url}/company/${editcompany ? "update" : "create"}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              _id: companyid,
              company: values.company,
              name: values.name,
              website: values.website,
              location: values.location,
              email: values.email.toLowerCase(),
              phone: values.phone,
              password: values.password,
              number_jobs_posted: 0,
              get_to_know: values.getToKnow,
              number_jobs_required: values.howManyJobs,
              model_type: values.modelType,
              calender_link: values.calender_link,
              company_privacy_policy: values.company_privacy_policy,
              client_id: "",
              approval_date: "",
              PPH_start_date: "",
              PPH_end_date: "",
              Subscription_start_date: "",
              Subscription_end_date: "",
              linkedin_company_id: editcompany
                ? values.linkedin_company_id
                : "64933526",
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.success === true) {
                !editcompany && setregistered(true);
                setIsSnackbarOpen({
                  state: true,
                  error: false,
                  msg: data.message,
                });
                history.push("/confirmation");
              } else {
                setIsSnackbarOpen({
                  state: true,
                  error: true,
                  msg: data.message[0].message[0],
                });
              }
            })
            .catch((err)=>handleError(err));
          resetForm({ values: { email: "", password: "" } });
        }}
        validationSchema={
          editcompany ? SignupEditformvalidation : Signupformvalidation
        }
      >
        {(props) => {
          const {
            values, // eslint-disable-line
            touched, // eslint-disable-line
            errors, // eslint-disable-line
            isSubmitting, // eslint-disable-line
            handleChange, // eslint-disable-line
            handleBlur, // eslint-disable-line
            handleSubmit, // eslint-disable-line
          } = props;

          const handleEmail = (e) => {
            setstopmail(true);
            if (editcompany) {
              handleChange(e);
            } else {
              setemail(e.target.value);
              handleChange(e);
            }
          };

          return (
            <>
              <form method="post" onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="company"
                  placeholder=" Name of the Company"
                  value={values.company}
                  onChange={handleChange}
                  disabled={editcompany}
                  onBlur={handleBlur}
                  className={`w-80-l w-90 w-80-m ${
                    errors.company && touched.company
                      ? "error mt0 mb0"
                      : "mt2-l mb2-l mt1 mb1"
                  }`}
                />
                {errors.company && touched.company && (
                  <p className="input-feedback">{errors.company}</p>
                )}

                <br />
                <input
                  type="text"
                  name="name"
                  placeholder=" Your Name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`w-80-l w-90 w-80-m ${
                    errors.name && touched.name
                      ? "error mt0 mb0"
                      : "mt2-l mb2-l mt1 mb1"
                  }`}
                />
                {errors.name && touched.name && (
                  <p className="input-feedback">{errors.name}</p>
                )}

                <br />

                <input
                  type="text"
                  name="website"
                  placeholder=" Website"
                  value={values?.website?.toLowerCase()}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`w-80-l w-90 w-80-m ${
                    errors.website && touched.website
                      ? "error mt0 mb0"
                      : "mt2-l mb2-l mt1 mb1"
                  }`}
                />
                {errors.website && touched.website && (
                  <p className="input-feedback">{errors.website}</p>
                )}

                <br />

                <input
                  type="text"
                  name="location"
                  placeholder=" Location"
                  value={values.location}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`w-80-l w-90 w-80-m ${
                    errors.location && touched.location
                      ? "error mt0 mb0"
                      : "mt2-l mb2-l mt1 mb1"
                  }`}
                />
                {errors.location && touched.location && (
                  <p className="input-feedback">{errors.location}</p>
                )}

                <br />

                <input
                  type="text"
                  name="email"
                  placeholder=" Email"
                  value={values?.email?.toLowerCase()}
                  disabled={editcompany}
                  onChange={(e) => handleEmail(e)}
                  onBlur={handleBlur}
                  className={`w-80-l w-90 w-80-m ${
                    (errors.email && touched.email) || err.is
                      ? "error mt0 mb0"
                      : "mt2-l mb2-l mt1 mb1"
                  }`}
                />
                {((errors.email && touched.email) || err.is) && (
                  <p className="input-feedback">
                    {err.is ? err.msg : errors.email}
                  </p>
                )}

                {editcompany && (
                  <>
                    <br />
                    <input
                      type="text"
                      name="linkedin_company_id"
                      placeholder=" Linkdeln Company Id"
                      value={values.linkedin_company_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`w-80-l w-90 w-80-m`}
                    />
                    <br />
                  </>
                )}

                {editcompany && (
                  <>
                    <br />
                    <input
                      type="text"
                      name="calender_link"
                      placeholder=" Calender Link"
                      value={values.calender_link}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`w-80-l w-90 w-80-m`}
                    />
                  </>
                )}
                <br />
                <input
                  type="tel"
                  name="phone"
                  placeholder=" Phone Number"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`w-80-l w-90 w-80-m ${
                    errors.phone && touched.phone
                      ? "error mt0 mb0"
                      : "mt2-l mb2-l mt1 mb1"
                  }`}
                />
                {errors.phone && touched.phone && (
                  <p className="input-feedback">{errors.phone}</p>
                )}

                {editcompany && (
                  <>
                    <br />
                    <input
                      type="text"
                      name="company_privacy_policy"
                      placeholder=" Company Privacy Policy"
                      value={values.company_privacy_policy}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`w-80-l w-90 w-80-m`}
                    />
                  </>
                )}

                {!editcompany ? (
                  <>
                    <br />
                    <input
                      type="password"
                      name="password"
                      placeholder={
                        editcompany
                          ? " Confirm password to edit details"
                          : " Password"
                      }
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`w-80-l w-90 w-80-m ${
                        errors.password && touched.password
                          ? "error mt0 mb0"
                          : "mt2-l mb2-l mt1 mb1"
                      }`}
                    />
                    {errors.password && touched.password && (
                      <p className="input-feedback">{errors.password}</p>
                    )}
                  </>
                ) : (
                  <></>
                )}

                <br />
                <input
                  type="text"
                  name="getToKnow"
                  placeholder="How did you get to Know about us?"
                  value={values.getToKnow}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`w-80-l w-90 w-80-m mb2-l mt1 mb1 ${
                    errors.getToKnow && touched.getToKnow
                      ? "error mt0 mb0"
                      : "mt2-l mb2-l mt1 mb1"
                  }`}
                />
                {errors.getToKnow && touched.getToKnow && (
                  <p className="input-feedback">{errors.getToKnow}</p>
                )}

                <br />
                <input
                  type="number"
                  name="howManyJobs"
                  placeholder="How many jobs are you willing to post in Moyyn?"
                  value={values.howManyJobs}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`w-80-l w-90 w-80-m mb2-l mt1 mb1 ${
                    errors.howManyJobs && touched.howManyJobs
                      ? "error mt0 mb0"
                      : "mt2-l mb2-l mt1 mb1"
                  }`}
                />
                {errors.howManyJobs && touched.howManyJobs && (
                  <p className="input-feedback">{errors.howManyJobs}</p>
                )}

                <br />
                <div className="flex flex-column justify-center items-center w-100">
                  <div className="mt3 tl w-80">
                    <RadioButtonsGroup
                      label="Which Model You're Interested in?"
                      name="modelType"
                      data={jobModelType}
                    />
                  </div>
                </div>
                <br />
                {!editcompany && (
                  <p className="f8">
                    By signing up, I agree to the{" "}
                    <Link
                      href="https://moyyn.com/terms-and-conditions-2/"
                      rel="noopener"
                      target="_blank"
                      style={{ cursor: "pointer", color: "#6EB6FF" }}
                    >
                      Terms of Use
                    </Link>{" "}
                    and{" "}
                    <Link
                      href="https://moyyn.com/privacy/"
                      rel="noopener"
                      target="_blank"
                      style={{ cursor: "pointer", color: "#6EB6FF" }}
                    >
                      Privacy Policy
                    </Link>
                    .{" "}
                  </p>
                )}
                <button
                  type="submit"
                  disabled={isSubmitting}
                  style={{ background: "#6EB6FF" }}
                  className="mt3 pointer fw6 f7 f6-l w-30-l w-40-m w-50 bn link dim br1 ph3 pv2 mb2 dib white"
                >
                  {editcompany ? "Make Changes" : "Submit"}
                </button>
              </form>
            </>
          );
        }}
      </Formik>
      <Snackbar
        open={IsSnackbarOpen.state}
        autoHideDuration={5000}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setIsSnackbarOpen(false)}
          severity={IsSnackbarOpen.error ? "error" : "success"}
        >
          {IsSnackbarOpen.msg}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default SignUpForm;
