import React from "react";
import { CircularProgress, Box, Typography } from "@mui/material";

const Loading = ({ text = false, small = false }) => {
  return (
    <Box m={small ? 0 : 2}>
      <Box display="flex" justifyContent="center">
        <CircularProgress size={small ? "0.8rem" : "3rem"} />
      </Box>
      {!!text && (
        <Typography
          style={{ marginTop: "2rem" }}
          align="center"
          color="textSecondary"
        >
          {text}
        </Typography>
      )}
    </Box>
  );
};

export default Loading;
