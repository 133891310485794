import React from "react";
import ReactQuill from "react-quill";
import FormError from "./FormError";

export const HtmlTextArea = ({ name, label, value, onChange, className="btex mt3", labelClassName = "gray f5-l f6-m f7" }) => {
  return (
    <div className={className}>
      <p className={labelClassName}>{label}</p>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={(html) => onChange(name, html)}
        modules={{
          clipboard: {
            matchVisual: false
          }
        }}
      />
      <FormError name={name} />
    </div>
  );
};
