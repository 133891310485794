/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useMediaQuery } from "react-responsive";
import DoneAllSharpIcon from "@mui/icons-material/DoneAllSharp";
import Lottie from "react-lottie";
import checkBox from "../../../assets/lotties/checkBox.json";
import { Google, LinkedIn } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useErrorHandler } from "react-error-boundary";

function Jobcard({ job, setjobid, backend_url, company, isPdl }) {
  const history = useHistory();
  const handleError = useErrorHandler();

  const [ttlcandidates, setttlcandidates] = useState(0);
  const [loading, setloading] = useState(false);
  const ismobile = useMediaQuery({ query: `(max-width: 580px)` });

  useEffect(() => {
    setloading(true);
    if (job._id !== "" || job._id !== undefined || job._id !== null) {
      fetch(`${backend_url}/momatch/${job._id}/1`, {
        headers: { M_AUTH_KEY: JSON.parse(localStorage.getItem("j_token")) },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setttlcandidates(res.count);
          }
          setloading(false);
        })
        .catch((err) => {
          setloading(false);
          handleError(err);
        });
    }
  }, [job, backend_url]);

  const now = new Date();
  const createdAt = new Date(Date.parse(job.timestamp));
  const oneDay = 24 * 60 * 60 * 1000;

  const getMultipostedDayCheck = () => {
    return now - createdAt > oneDay;
  };

  const getPostedJobDay = () => {
    return parseInt((now - createdAt) / oneDay);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: checkBox,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      style={{ borderRadius: "6px", height: "fit-content", padding: "1.6rem" }}
      className=" w-100 relative ma1 dib ch bg-white mt3 br4"
    >
      <div className="flex justify-between items-start mt2-l mt0">
        <div style={{ flex: 0.8 }} className="flex  items-center">
          <div className="flex w-100 name_candidatebox flex-column">
            <p
              style={{
                wordWrap: "break-word",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                display: "-webkit-box",
              }}
              className="ma0 di f6-l f6-m mt1 f7_2-mo"
            >
              {job.jobTitle}
            </p>
            <p className="ma0 gray mt1 f8 f9-m f9-mo">{job.careerLevel}</p>
            <p
              style={{ color: "#6EB6FF" }}
              className="ma0 gray f8 mt1 f9-m f9-mo"
            >
              {job.city}, {job.country}
            </p>
          </div>
        </div>
        <div className="flex-1 flex justify-around items-center">
          <div
            onClick={() => {
              setjobid(job._id);
              localStorage.setItem("j_id", JSON.stringify(job._id));
              history.push("/Editjob");
            }}
            className="ico mb2 pointer flex flex-column items-center self-center"
          >
            <div style={{ color: "gray" }}>
              <EditIcon />
            </div>
            <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">
              Edit {ismobile ? "" : "Job"}
            </p>
          </div>
          <a
            href={`https://jobs.moyyn.com/${company}/${encodeURIComponent(
              job.jobTitle,
            )}-${encodeURIComponent(
              job.jobCode,
            )}?jobType=Direct%20Client&jobid=${job._id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            <div className="ico mb2 pointer flex flex-column items-center self-center">
              <div style={{ color: "gray" }}>
                <VisibilityIcon />
              </div>

              <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">
                View {ismobile ? "" : "Job"}
              </p>
            </div>
          </a>
        </div>
        <div className="h-100 flex-1 w-25-l w-40-m w-30 mb2 mt3-l">
          <div className="w-100 flex justify-end flex-row-l flex-row-m flex-column mt1 items-center">
            <Tooltip title={isPdl ? "Active Sourcing" : "Coming Soon"}>
              <a
                href={
                  isPdl
                    ? `${process.env.REACT_APP_SOURCE_ROUTE}?cid=${JSON.parse(
                        localStorage.getItem("c_id"),
                      )}&jid=${job._id}`
                    : "#"
                }
                style={{ color: "#6EB6FF", opacity: isPdl ? "1" : "0.4" }}
                className="bg-white pointer ml2-l ml2-m ml3 c-shadow f9-mo f8-m f7-l w-50-l w-50-m w-90 h2-l h2-m h7-mo mt1 mt0-l mt0-m bn link dim br2 ph3 pv2 dib tc"
              >
                Active Sourcing
              </a>
            </Tooltip>
            <button
              onClick={() => {
                setjobid(job._id);
                localStorage.setItem("j_id", JSON.stringify(job._id));
                history.push("/Candidates");
              }}
              style={{ background: "#6EB6FF" }}
              className="c-shadow ml2-l ml2-m ml3 pointer f9-mo f8-m f7-l mr2-l mr2-m mr0 w-50-l w-50-m w-90 h2-l h2-m h7-mo bn link dim br2 ph3 pv2 dib white"
            >
              Manage
            </button>
          </div>
        </div>
      </div>
      {/* <Tooltip title={`auto delete in ${30 - getPostedJobDay()} days`}>
        <div
          // onClick={handleOpen}
          style={{ top: "0.4rem", right: "0.6rem" }}
          className="absolute pointer gray"
        >
          &times;
        </div>
      </Tooltip> */}
      <div className="absolute top-0 left-1 ma0 br2 gray f8 f8-m f9-mo mt2 ml2">
        {getMultipostedDayCheck() ? (
          <div className="flex items-center">
            <DoneAllSharpIcon color="success" fontSize="small" />
            <span className="mh1">Multiposted at </span>
            <LinkedIn fontSize="inherit" />
            <span className="mr1">,</span>
            <Google fontSize="inherit" />
            <span className="ml1">& many more</span>
          </div>
        ) : (
          <div className="flex items-center">
            <Lottie options={defaultOptions} height={20} width={20} />
            <span className="mh1">Multiposting to</span>
            <LinkedIn fontSize="inherit" />
            <span className="mr1">,</span>
            <Google fontSize="inherit" />
            <span className="ml1">& many more</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Jobcard;
