import React, { useState, useEffect, createContext } from "react";
import "tachyons";
import "../../styles/postjob.scss";
import { Stepper, Step, StepLabel } from "@mui/material";
import { useHistory } from "react-router-dom";
import Loading from "../Loading/Loading";
import "react-quill/dist/quill.snow.css";
import Step1 from "./FormSteps/Step1";
import Step2 from "./FormSteps/Step2";
import InProgress from "../Dashboard/InProgress";
import { useErrorHandler } from "react-error-boundary";

export const pdlContext = createContext();

function JobForm({
  postjobinitialvalues,
  backend_url,
  momatch_backend_url,
  companyid,
  jobid,
  editjob,
  setjobid,
  companyData,
  setjobcode,
  status,
  token,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(postjobinitialvalues);
  const [currentStep, setCurrentStep] = useState(0);
  const [jobData, setJobData] = useState(companyData);
  const [submitMomatchFormTrigger, setSubmitMomatchFormTrigger] = useState(false);
 const [submitCreditFormTrigger, setSubmitCreditFormTrigger] = useState(false);

  const [inProgress, setInProgress] = useState(false);
  const history = useHistory();
  const handleError = useErrorHandler();

  const handleFormChange = (values, complete = true, submitOne = false) => {
    if (complete) {
      setFormValues({ ...values });
    }

    if (submitOne) {
      setSubmitMomatchFormTrigger(true);
    }
  };

  const checkArrays = (arrA, arrB) => {
    if (arrA.length !== arrB.length) return false;
    const cA = arrA.slice().join(",");
    const cB = arrB.slice().join(",");
    return cA === cB;
  };

  const getCorrectedJobLevel = (titleLevel, isForward = true) => {
    const job_title_levels = {
      0: ["unpaid", "training"],
      1: ["entry"],
      2: ["entry", "senior"],
      3: ["senior", "manage", "vp"],
      4: ["cxo", "owner", "director", "vp", "partner"],
    };
    let levelIndex = 0;
    // eslint-disable-next-line array-callback-return
    Array.from({ length: 5 }).map((_, idx) => {
      if (checkArrays(job_title_levels[idx], titleLevel)) levelIndex = idx;
    });
    if (isForward) {
      if (levelIndex === 4) return titleLevel;
      else return job_title_levels[levelIndex + 1];
    } else {
      if (levelIndex === 0) return titleLevel;
      else return job_title_levels[levelIndex - 1];
    }
  };

  useEffect(() => {
    if (editjob && !companyid) return;
    fetch(backend_url + "/company/find", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        _id: companyid,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) { 
          setJobData(data.result)
        }
      })
  }, [companyid])

  useEffect(() => {
    if (!submitMomatchFormTrigger && !submitCreditFormTrigger) return;
    if (submitMomatchFormTrigger) submitMomatchForm();
    else submitCreditForm();
  }, [submitMomatchFormTrigger, submitCreditFormTrigger]);

  const submitWorkExCandidateMailTrigger = () => {
    fetch(`${momatch_backend_url}/momatch/job/post`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        _id: jobid,
        company: companyid,
        relevantJobTitle: formValues.relevantJobTitle,
        jobTitle: formValues.jobTitle,
        jobType: formValues.jobType,
        requirements: formValues.requirements,
        description: formValues.description,
        careerLevel: formValues.careerLevel,
        Date: formValues.Date,
        Industries: formValues.Industries,
        Languages: formValues.Languages,
        Skills: [...new Set(formValues.Skills)],
        skills_secondary: [...new Set(formValues.skills_secondary)],
        jobCategory: formValues.jobCategory,
        workExperience: formValues.workExperience,
        city: formValues.city,
        country: formValues.country,
        state: formValues.state,
        postcode: formValues.postcode,
        currency: formValues.currency,
        from: formValues.from,
        to: formValues.to,
        otherCountries: formValues.otherCountries,
        timestamp: new Date(),
        category: "Free",
        linkedin_company_id: "64933526",
      }),
    });
  };

  const submitPDLQuery = (jobId) => {
    const jobLevels = (() => {
      switch (formValues.careerLevel) {
        case "Student":
          return ["unpaid", "training"];
        case "Graduate":
          return ["entry"];
        case "Young Professional":
          return ["entry", "senior"];
        case "Senior":
          return ["senior", "manage", "vp"];
        case "Expert":
          return ["cxo", "owner", "director", "vp", "partner"];
      }
    })();
    //   ...formValues.Languages.map((obj) => {
    //     let ans = {
    //       name: obj.language,
    //       proficiency: 0,
    //     };
    //     switch (obj.level) {
    //       case "A1":
    //         ans.proficiency = 1;
    //         break;
    //       case "A2":
    //         ans.proficiency = 2;
    //         break;
    //       case "B1":
    //       case "B2":
    //         ans.proficiency = 3;
    //         break;
    //       case "C1":
    //       case "C2":
    //         ans.proficiency = 4;
    //         break;
    //       default:
    //         ans.proficiency = 5;
    //     }
    //     return ans;
    //   }),
    // ];
    const skillsData = [...new Set(formValues.Skills)];
    fetch(`${momatch_backend_url}/pdl/count`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        company_id: companyid,
        job_id: jobId,
        user_input: {
          q1: {
            job_title: formValues.relevantJobTitle,
            job_title_levels: jobLevels,
            skills: skillsData,
            location_country: [formValues.country],
          },
          q2: {
            job_title: formValues.relevantJobTitle,
            job_title_levels: jobLevels,
            skills: skillsData,
            location_country: ["germany", "austria", "switzerland"],
          },
          q3: {
            job_title: formValues.relevantJobTitle,
            job_title_levels: jobLevels,
            skills: skillsData,
            location_country: [
              "Belgium",
              "Cyprus",
              "Estonia",
              "Finland",
              "France",
              "Greece",
              "Ireland",
              "Italy",
              "Latvia",
              "Lithuania",
              "Luxembourg",
              "Malta",
              "Netherlands",
              "Portugal",
              "Slovakia",
              "Slovenia",
              "Spain",
            ],
          },
          q4: {
            job_title: formValues.relevantJobTitle,
            job_title_levels: jobLevels,
            skills: skillsData,
            location_country: [
              "afghanistan",
              "albania",
              "algeria",
              "american samoa",
              "andorra",
              "angola",
              "anguilla",
              "antarctica",
              "antigua and barbuda",
              "argentina",
              "armenia",
              "aruba",
              "australia",
              "azerbaijan",
              "bahamas",
              "bahrain",
              "bangladesh",
              "barbados",
              "belarus",
              "belize",
              "benin",
              "bermuda",
              "bhutan",
              "bolivia",
              "bosnia and herzegovina",
              "botswana",
              "bouvet island",
              "brazil",
              "british indian ocean territory",
              "british virgin islands",
              "brunei",
              "bulgaria",
              "burkina faso",
              "burundi",
              "cambodia",
              "cameroon",
              "canada",
              "cape verde",
              "caribbean netherlands",
              "cayman islands",
              "central african republic",
              "chad",
              "chile",
              "china",
              "christmas island",
              "cocos (keeling) islands",
              "colombia",
              "comoros",
              "cook islands",
              "costa rica",
              "croatia",
              "cuba",
              "curaçao",
              "czechia",
              "côte d’ivoire",
              "democratic republic of the congo",
              "denmark",
              "djibouti",
              "dominica",
              "dominican republic",
              "ecuador",
              "egypt",
              "el salvador",
              "equatorial guinea",
              "eritrea",
              "ethiopia",
              "falkland islands",
              "faroe islands",
              "fiji",
              "french guiana",
              "french polynesia",
              "french southern territories",
              "gabon",
              "gambia",
              "georgia",
              "ghana",
              "gibraltar",
              "greenland",
              "grenada",
              "guadeloupe",
              "guam",
              "guatemala",
              "guernsey",
              "guinea",
              "guinea-bissau",
              "guyana",
              "haiti",
              "heard island and mcdonald islands",
              "honduras",
              "hong kong",
              "hungary",
              "iceland",
              "india",
              "indonesia",
              "iran",
              "iraq",
              "isle of man",
              "israel",
              "ivory coast",
              "jamaica",
              "japan",
              "jersey",
              "jordan",
              "kazakhstan",
              "kenya",
              "kiribati",
              "kosovo",
              "kuwait",
              "kyrgyzstan",
              "laos",
              "lebanon",
              "lesotho",
              "liberia",
              "libya",
              "liechtenstein",
              "macau",
              "macedonia",
              "madagascar",
              "malawi",
              "malaysia",
              "maldives",
              "mali",
              "marshall islands",
              "martinique",
              "mauritania",
              "mauritius",
              "mayotte",
              "mexico",
              "micronesia",
              "moldova",
              "monaco",
              "mongolia",
              "montenegro",
              "montserrat",
              "morocco",
              "mozambique",
              "myanmar",
              "namibia",
              "nauru",
              "nepal",
              "netherlands antilles",
              "new caledonia",
              "new zealand",
              "nicaragua",
              "niger",
              "nigeria",
              "niue",
              "norfolk island",
              "north korea",
              "northern mariana islands",
              "norway",
              "oman",
              "pakistan",
              "palau",
              "palestine",
              "panama",
              "papua new guinea",
              "paraguay",
              "peru",
              "philippines",
              "pitcairn",
              "poland",
              "puerto rico",
              "qatar",
              "republic of the congo",
              "romania",
              "russia",
              "rwanda",
              "réunion",
              "saint barthélemy",
              "saint helena",
              "saint kitts and nevis",
              "saint lucia",
              "saint martin",
              "saint pierre and miquelon",
              "saint vincent and the grenadines",
              "samoa",
              "san marino",
              "saudi arabia",
              "senegal",
              "serbia",
              "seychelles",
              "sierra leone",
              "singapore",
              "sint maarten",
              "solomon islands",
              "somalia",
              "south africa",
              "south georgia and the south sandwich islands",
              "south korea",
              "south sudan",
              "sri lanka",
              "sudan",
              "suriname",
              "svalbard and jan mayen",
              "swaziland",
              "sweden",
              "syria",
              "são tomé and príncipe",
              "taiwan",
              "tajikistan",
              "tanzania",
              "thailand",
              "timor-leste",
              "togo",
              "tokelau",
              "tonga",
              "trinidad and tobago",
              "tunisia",
              "turkey",
              "turkmenistan",
              "turks and caicos islands",
              "tuvalu",
              "u.s. virgin islands",
              "uganda",
              "ukraine",
              "united arab emirates",
              "united kingdom",
              "united states",
              "united states minor outlying islands",
              "uruguay",
              "uzbekistan",
              "vanuatu",
              "vatican city",
              "venezuela",
              "vietnam",
              "wallis and futuna",
              "western sahara",
              "yemen",
              "zambia",
              "zimbabwe",
              "Åland islands",
              "åland islands",
            ],
          },
          q5: {
            job_title: formValues.relevantJobTitle,
            job_title_levels: jobLevels,
            skills: skillsData,
            location_country: [formValues.country],
          },
          q6: {
            job_title: formValues.relevantJobTitle,
            job_title_levels: getCorrectedJobLevel(jobLevels),
            skills: skillsData,
            location_country: [formValues.country],
          },
          q7: {
            job_title: formValues.relevantJobTitle,
            job_title_levels: getCorrectedJobLevel(jobLevels, false),
            skills: skillsData,
            location_country: [formValues.country],
          },
        },
      }),
    }).catch((err) => {
      handleError(err);
    });
  };

  const submitMomatchForm = () => {
    fetch(`${backend_url}/job/${editjob ? "update" : "create"}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        _id: jobid,
        company: companyid,
        relevantJobTitle: formValues.relevantJobTitle,
        jobTitle: formValues.jobTitle,
        jobType: formValues.jobType,
        requirements: formValues.requirements,
        description: formValues.description,
        careerLevel: formValues.careerLevel,
        Date: formValues.Date,
        Industries: formValues.Industries,
        Languages: formValues.Languages,
        Skills: [...new Set(formValues.Skills)],
        skills_secondary: [...new Set(formValues.skills_secondary)],
        jobCategory: formValues.jobCategory,
        workExperience: formValues.workExperience,
        city: formValues.city,
        country: formValues.country,
        state: formValues.state,
        postcode: formValues.postcode,
        currency: formValues.currency,
        from: formValues.from,
        to: formValues.to,
        otherCountries: formValues.otherCountries,
        timestamp: new Date(),
        category: "Free",
        linkedin_company_id: "64933526",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (!editjob) {
            setjobid(data.result._id);
            setjobcode(data.result.jobCode);
            localStorage.setItem("j_id", JSON.stringify(data.result._id));
            localStorage.setItem("j_code", JSON.stringify(data.result.jobCode));
            fetch(`${momatch_backend_url}/momatch`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                jobid: data.result._id,
                timestamp: new Date(),
                page: 1,
              }),
            })
              .then((response) => response.json())
              .then((data) => {
                if (
                  data.exists ||
                  data.error === "data not found from momatch" ||
                  data.success
                ) {
                  setInProgress(false);
                  if (jobData?.isPdl) submitPDLQuery(jobid);
                  submitWorkExCandidateMailTrigger();
                  setSubmitMomatchFormTrigger(false);
                  history.push("/dashboard");
                }
              });
          } else {
            if (status !== "In-Active") {
              setIsLoading(true);
              fetch(`${momatch_backend_url}/momatch/${jobid}`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  jobid: jobid,
                  country: formValues.otherCountries,
                }),
              })
                .then((response) => response.json())
                .then((data) => {
                  if (data.success) {
                    setIsLoading(false);
                    if (jobData?.isPdl) submitPDLQuery(jobData._id);
                    history.push("/dashboard");
                  } else {
                    setIsLoading(false);
                    handleError(
                      `momatch error on posting new job with JobId: ${jobid}, PUT api error`,
                    );
                  }
                })
                .catch((err) => {
                  handleError(
                    `error in submitting job form for companyID: ${companyid}`,
                  );
                  console.error("error submitting job form!", err);
                });
            }
          }
        } else {
          handleError("job form api returning success as false");
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const submitCreditForm = () => {
    setSubmitCreditFormTrigger(false);
  };

  if (inProgress) {
    return <InProgress />;
  }

  if (isLoading) {
    return (
      <>
        <div className="flex items-center w-80 justify-center bg-white ma3 br2 vh-75">
          <Loading text="AI Matchmaking in Progress.." />
        </div>
      </>
    );
  }

  if (submitMomatchFormTrigger) {
    return (
      <>
        <div className="flex items-center w-80 justify-center bg-white ma3 br2 vh-75">
          <Loading text="Submitting Job" />
        </div>
      </>
    );
  }

  const handleNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };
  const handlePrevStep = () => setCurrentStep((prev) => prev - 1);
  const steps = [
    {
      component: (
        <Step1
          key={0}
          handlePrevStep={handlePrevStep}
          handleNextStep={handleNextStep}
          handleFormChange={handleFormChange}
          initailFormValues={formValues}
        />
      ),
      label: "Step 1",
    },
    {
      component: (
        <Step2
          key={1}
          editjob={editjob}
          handlePrevStep={handlePrevStep}
          handleNextStep={handleNextStep}
          handleFormChange={handleFormChange}
          initailFormValues={formValues}
        />
      ),
      label: "Step 2",
    },
  ];

  return (
    <div className="jobform flex-1 w-100 vh-100">
      <div
        style={{ background: "#eef2f5" }}
        className="w-100 flex flex-row-l flex-row-m flex-column-reverse"
      >
        <div className="flex-1 flex-none"></div>
        <div className="flex-4 pv2 flex-1-mo w-90 w-100-l w-100-m mv4 center br2 ba2 bg-white flex justify-center items-center flex-column">
          <Stepper activeStep={currentStep}>
            {steps.map(({ label }) =>
              label ? (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ) : null,
            )}
          </Stepper>
          {steps[currentStep].component}
        </div>
        <div className="flex-1"></div>
      </div>
    </div>
  );
}

export default JobForm;
