import React from "react";
import "tachyons";
import "../../styles/RegistrationConformation.scss";

function RegistrationConformation() {
  return (
    <div className="dashboard items-center flex flex-column w-60-l w-80-m w-90 tc justify-center">
      <h4 className="dasheading mb5-l mb5-m mb4 f2-l f2-m f5">
        Welcome to Moyyn!
      </h4>
      <div className="center dib mb5-l mb5-m mb4 f5 w-80 tl pa3 shadow-2 dashabt">
        <p>
          Welcome to Moyyn, <br />
          <br />{" "}
          {`It's great that you have decided to choose Moyyn to support
          your recruitment efforts. Up next, one of our Talent Managers will get
          in touch with you for onboarding and understanding the needs. Once
          your onboarding is done, you will be able to login for your personal
          talent dashboard.`}{" "}
          <br />
          <br />
          {` Let's hire your next talent in just 15 days! `}
          <br />
          <br />
          Be safe and sound!
          <br />
          Moyyn
        </p>
      </div>
      <a
        className="mt3 fw6 f7 f6-l w-20-l w-40-m w-50 bn link dim br2 center dashbtn pv3-m ph4-m ph3 pv2 dib white"
        href="https://moyyn.com/"
      >
        Moyyn Homepage
      </a>
    </div>
  );
}

export default RegistrationConformation;
