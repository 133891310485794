import React, { useEffect, useState } from "react";
import { Snackbar } from "@mui/material";
import { Formik } from "formik";
import MuiAlert from "@mui/material/Alert";
import { HtmlTextArea } from "../../Common/HtmlTextArea";
import { careerPageFormValidation } from "../../../util/validation/form-validation";
import { careerPageFormInitialValues } from "../../../util/data/initial-values";
import DropZoneImage from "../../Common/FileDropZone";

export const Career = ({ momatchBackendUrl, companyId }) => {
  const [image, setImage] = useState(null);
  const [responseSaved, setResponseSaved] = useState(false);
  const [initialValues, setInitialValues] = useState(
    careerPageFormInitialValues,
  );

  useEffect(() => {
    if (!companyId) return;
    fetch(momatchBackendUrl + "/company/page/fetch", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        companyId: companyId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setInitialValues(data);
          setImage(data.logo);
        }
      });
  }, []);

  return (
    <div className="mv4 pa3 w-95 center flex-1 tc bg-white">
      <p className="gray tc f3">Career Page</p>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values) => {
          fetch(momatchBackendUrl + "/company/page/publish", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              companyId: companyId,
              logo: image,
              about: values.about,
              benefits: values.benefits,
              workCulture: values.workCulture,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.success) {
                setResponseSaved(true);
              }
            });
        }}
        validationSchema={careerPageFormValidation}
      >
        {(props) => {
          const { values, handleSubmit, setFieldValue } = props;

          return (
            <div className="w-80-l w-90 w-80-m mt2-l mb2-l mt1 mb1 tl ml-auto mr-auto">
              <div className="btex mt3">
                <p className="gray f5-l f6-m f7">Upload Company Logo</p>
                <div className="b--dashed pa4 tc b--gray pointer">
                  <DropZoneImage setImage={setImage} />
                </div>
                {image && (
                  <>
                    <p className="gray f5-l f6-m f7">Preview</p>
                    <img
                      style={{ objectFit: "contain" }}
                      className="mt3"
                      src={image}
                      alt="logo preview"
                      height={250}
                      width={250}
                    />
                  </>
                )}
              </div>
              <HtmlTextArea
                value={values.about}
                onChange={setFieldValue}
                label="About the Company"
                name="about"
              />
              <HtmlTextArea
                value={values.benefits}
                onChange={setFieldValue}
                label="Company Benefits"
                name="benefits"
              />
              <HtmlTextArea
                value={values.workCulture}
                onChange={setFieldValue}
                label="Work Culture"
                name="workCulture"
              />
              <div className="mt4 tc">
                <button
                  className="mt3 mh2 pointer fw6 f7 f6-l w-20-l w-30 bn link dim br1 ph3 pv1 ph4-m pv2-m pv2-l dib white bg-white tracked dim"
                  onClick={handleSubmit}
                  style={{ background: "#6EB6FF", color: "white" }}
                >
                  Publish
                </button>
              </div>
            </div>
          );
        }}
      </Formik>
      <Snackbar
        open={responseSaved}
        autoHideDuration={5000}
        onClose={() => {
          setResponseSaved(false);
        }}
      >
        <MuiAlert elevation={6} variant="filled" severity={"success"}>
          Successfully Published!
        </MuiAlert>
      </Snackbar>
    </div>
  );
};
