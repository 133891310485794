import React, { useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import FormError from "../../Common/FormError";
import DateForm from "../Formelements/DateForm";
import AutocompleteChips from "../Formelements/AutocompleteChipsForm";
import {
  industries,
  currency,
  jobTypeData,
} from "../../../util/data/static-data";
import SelectMenu from "../Formelements/SelectMenuForm";
import { Formik } from "formik";
import { Postjobstep1formvalidation } from "../../../util/validation/form-validation";
import { HtmlTextArea } from "../../Common/HtmlTextArea";
import PDLFetchField from "../Formelements/PDLFetchField";
import RadioButtonsGroup from "../Formelements/RadioOptions";

export default function Step1({
  initailFormValues,
  handleNextStep,
  handleFormChange,
}) {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <Formik
      initialValues={initailFormValues}
      enableReinitialize={true}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        try {
          const { flexibleDate, Date } = values;
          const parsedValue = { ...values };
          parsedValue.Date = flexibleDate ? 'flexible' : Date;
          handleFormChange(parsedValue, true, false, false);
        } catch {
          handleFormChange(values, true, false, false);
        }
        handleNextStep();
        setSubmitting(true);
      }}
      validationSchema={Postjobstep1formvalidation}
    >
      {(props) => {
        const {
          isSubmitting,
          values, // eslint-disable-line
          handleChange, // eslint-disable-line
          handleBlur, // eslint-disable-line
          handleSubmit, // eslint-disable-line
          setFieldValue,
        } = props;
        return (
          <>
            <p className="gray f3">Enter Job Details</p>
            <div className={`tf w-80-l w-90 w-80-m `}>
              <TextField
                name="jobTitle"
                id="outlined-basic"
                label="Job Title"
                variant="outlined"
                value={values.jobTitle}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-100"
              />
              <FormError name="jobTitle" />
            </div>

            <div className="mt3 w-80-l w-90 w-80-m">
              <PDLFetchField
                name="relevantJobTitle"
                placeholder="Similar job titles (for sourcing)"
                pdlName="title"
              />
            </div>

            <div className="w-80-l w-90 w-80-m mt2-l mb2-l mt1 mb1">
              <HtmlTextArea
                value={values.description}
                onChange={setFieldValue}
                label="Job Description"
                name="description"
              />
              <HtmlTextArea
                value={values.requirements}
                onChange={setFieldValue}
                label="Job Requirements"
                name="requirements"
              />
            </div>
            <div className="w-80-l w-90 w-80-m mt2-l mb2-l mt1 mb1">
              <div className="mt3">
                <AutocompleteChips
                  className="flex-1"
                  name="Industries"
                  label="Industries"
                  options={industries}
                />
              </div>
            </div>
            <div className="w-80-l w-90 w-80-m mt2-l mt1">
              <RadioButtonsGroup
                label="Job Type"
                name="jobType"
                data={jobTypeData}
              />
            </div>
            <div className="sald flex flex-column flex-row-m flex-row-l w-80 mt2 center items-center justify-between">
              <div className="flex-1 w-100">
                <p className="gray ma0 mb3-l mb2 f6">Start Date</p>
                {!isChecked && (
                  <Box mt={2}>
                    <DateForm name="Date" />
                  </Box>
                )}
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="flexibleDate"
                        size="small"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Flexible joining"
                    sx={{
                      ".MuiFormControlLabel-label": {
                        color: "#858585",
                        fontSize: "0.8rem",
                      },
                    }}
                  />
                </FormGroup>
              </div>
              <div className="sal flex flex-1 pt2 pt0-l pt0-m ml5-l ml5-m ml0 flex-column">
                <div className="flex flex-column items-start">
                  <p className="gray ma0 mb3-l mb2 f6">Salary (Optional)</p>
                  <div>
                    <SelectMenu
                      name="currency"
                      label="Currency"
                      options={currency}
                    />
                  </div>
                </div>
                <div className="flex mt3 ">
                  <div>
                    <TextField
                      name="from"
                      id="filled-number"
                      label="From"
                      type="number"
                      value={values.from}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                    <FormError name="from" />
                  </div>
                  <div className="ml3">
                    <TextField
                      name="to"
                      id="filled-number"
                      label="To"
                      type="number"
                      value={values.to}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                    <FormError name="to" />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 flex flex-row justify-center">
              <button
                type="submit"
                disabled={isSubmitting}
                style={{ background: "#6EB6FF", height: "40px" }}
                onClick={() => handleSubmit()}
                className="mt3 mh2 pointer fw6 f7 f6-l w-20-l w-30 bn link dim br1 ph3 pv1 ph4-m pv2-m  pv2-l dib white bg-white"
              >
                Next
              </button>
            </div>
          </>
        );
      }}
    </Formik>
  );
}
