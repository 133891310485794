import React, { useState } from "react";
import { Button } from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import "tachyons";
import TextField from "@mui/material/TextField";
import "../../styles/setting.scss";
import Alert from "@mui/material/Alert";
import { Formik } from "formik";
import { ResetPass as validationSchema } from "../../util/validation/form-validation";
import { resetforminitialvalue as initialValues } from "../../util/data/initial-values";
import FormError from "./FormError";
import { useErrorHandler } from 'react-error-boundary'

export default function Resetpass({ mo_backend_url }) {
  const history = useHistory();

  const [reseted, setreseted] = useState(null);
  const [msg, setmsg] = useState("");
  const handleError = useErrorHandler()

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("tkn");

  const handleRequest = (values, { resetForm }) => {
    fetch(`${mo_backend_url}/reset/password`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        tkn: id,
        otp: values.otp,
        password: values.password,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setreseted(true);
          setmsg("Password reset Succesfull!");
          resetForm({ values: "" });
        } else {
          setreseted(false);
          setmsg("Wrong Credentials. Please try again!");
        }
      })
      .catch((err) => {
        handleError(err)
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleRequest();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleRequest}
    >
      {(props) => {
        const {
          values, // eslint-disable-line
          handleChange, // eslint-disable-line
          handleBlur, // eslint-disable-line
          submitForm,
        } = props;
        return (
          <div className="w-100 vh-75" style={{ background: "#eef2f5" }}>
            <div className="center br2 mv5 tc bg-white w-60-l w-80-m w-90 pa4-l pa4-m pa3 ma2">
              <p className="gray tc f3-l f4-m f5">Reset Password</p>
              <div>
                <div className="creds mt3 w-100">
                  <TextField
                    name="otp"
                    label="One Time Password"
                    variant="outlined"
                    value={values.otp}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    autoComplete="off"
                    className={`w-50-l w-70-m w-80`}
                  />
                  <div className="flex justify-center items-center">
                    <FormError name="otp" />
                  </div>
                </div>
                <div className="creds mt3">
                  <TextField
                    name="password"
                    label="New Password"
                    type="password"
                    variant="outlined"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    autoComplete="off"
                    className={`w-50-l w-70-m w-80`}
                  />
                  <div className="flex justify-center items-center">
                    <FormError name="password" />
                  </div>
                </div>
                <div className="creds mt3">
                  <TextField
                    name="confirmpassword"
                    label="Confirm New Password"
                    type="password"
                    variant="outlined"
                    value={values.confirmpassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    autoComplete="off"
                    className={`w-50-l w-70-m w-80`}
                  />
                  <div className="flex justify-center items-center">
                    <FormError name="confirmpassword" />
                  </div>
                </div>
                <div className="mt4">
                  <Button
                    type="submit"
                    onClick={() => submitForm()}
                    className="dim"
                    variant="outlined"
                    style={{ borderColor: "#6EB6FF", color: "#6EB6FF" }}
                  >
                    Update <span className="hide-mo ml1">Password</span>
                  </Button>
                </div>
                <div className={`${reseted !== null ? "" : "hide"} mt4`}>
                  <Alert severity={`${reseted ? "success" : "error"}`}>
                    {msg}{" "}
                    {reseted && (
                      <span
                        onClick={() => history.push("/login")}
                        className="blue f6-l f6-m f7 pointer dim"
                      >
                        Go to login
                      </span>
                    )}
                  </Alert>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
}
