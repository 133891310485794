import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { useHistory } from "react-router-dom";
import { creditsEuroMap } from "../../CreditManagement/CreditManagement";
import Loading from "../../Loading/Loading";

export default function Credits({ companyId, momatchBackendUrl }) {
  const history = useHistory();
  const handleError = useErrorHandler()

  const [cid] = useState(companyId);
  const [historyList, setHistoryList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getPrice = (credits) => {
    let cost = 0;
    Object.keys(creditsEuroMap).forEach((val) => {
      if (credits >= parseInt(val)) cost = creditsEuroMap[parseInt(val)];
    })
    return cost;
  };

  useEffect(() => {
    if (!cid) return;
    setIsLoading(true);
    fetch(`${momatchBackendUrl}/credit/history`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        company_id: cid,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          const history = data.history.map((history) => {
            if (history.value <= 0) return null;
            return {
              ...history,
              date: new Date(history.timestamp).toLocaleDateString(),
              time: new Date(history.timestamp).toLocaleTimeString(),
            };
          });
          setHistoryList(history.filter((val) => val != null));
        }
      })
      .catch((err) => handleError(err)).finally(()=> setIsLoading(false));
    setRefresh(false);
  }, [cid, refresh, setRefresh]);


  if (isLoading) {
    return (
      <React.Fragment>
        <div className="flex w-100 items-center justify-center bg-white ma3 br2 vh-75">
          <Loading text="Loading credits history" />
        </div>
      </React.Fragment>
    );
  }

  if (historyList.length <= 0) {
    return (
      <React.Fragment>
        <div className="flex flex-column items-center justify-center bg-white ma3 br2 w-100">
          <p className="f3 gray">No credit history found!</p>
          <button
            className="mt4 fw6 f6 bn dim br1 ph3 pointer pv2 dib white"
            style={{ background: "#6EB6FF" }}
            onClick={() => history.push("/dashboard")}
          >
            Go Back
          </button>
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className="flex flex-column justify-center items-center w-100">
      <div className="overflow-auto bg-white br2 w-100">
        <table className="f6 w-100 mw8 center" cellSpacing="0">
          <thead>
            <tr>
              <th className="fw6 bb b--black-20 tl pb3 pr3 bg-white">Credit</th>
              <th className="fw6 bb b--black-20 tl pb3 pr3 bg-white">Date</th>
              <th className="fw6 bb b--black-20 tl pb3 pr3 bg-white">Time</th>
              <th className="fw6 bb b--black-20 tl pb3 pr3 bg-white">Price</th>
            </tr>
          </thead>
          <tbody className="lh-copy">
            {historyList.map((val, idx) => {
              return (
                <tr key={idx}>
                  <td className="pv3 pr3 bb b--black-20">{val.value}</td>
                  <td className="pv3 pr3 bb b--black-20">{val.date}</td>
                  <td className="pv3 pr3 bb b--black-20">{val.time}</td>
                  <td className="pv3 pr3 bb b--black-20">
                    &euro;{getPrice(val.value)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
