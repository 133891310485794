import React, {
  useEffect,
  // useRef
} from "react";
import CloseIcon from "@mui/icons-material/Close";

const InProgress = ({ close }) => {
  // const ref = useRef();
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  });
  // useEffect(() => {
  //   const handler = (event) => {
  //     if (ref.current && !ref.current.contains(event.target)) close();
  //   };
  //   const element = document.querySelector("#inProgressBackground");
  //   element.addEventListener("click", handler);
  //   return () => {
  //     element.removeEventListener("click", handler);
  //   };
  // }, [ref]);
  return (
    <main
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.2)",
        zIndex: "2",
        position: "absolute",
        top: "0",
        left: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      id="inProgressBackground"
    >
      <section
        style={{
          width: "600px",
          height: "500px",
          backgroundColor: "white",
          borderRadius: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
        // ref={ref}
      >
        <img src="/workInProgress.gif" alt="loading" className="w-70" />
        <span
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            cursor: "pointer",
          }}
          // onClick={()=>close()}
        >
          <CloseIcon />
        </span>
        <h1 className="gray">Active sourcing in progress</h1>
      </section>
    </main>
  );
};

export default InProgress;
