/* eslint-disable */
import React, { useState, useEffect } from "react";
import "tachyons";
import JobCardList from "../Dashboard/JobCard/Jobcardlist";
import "../../styles/dashboard.scss";
import "../../styles/custom.css";
import { useHistory, useLocation } from "react-router-dom";
import FreeBanner from "../Candidates/FreeTrialPrompt";
import quirin from "../../svg/quirin.png";
import { Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Loading from "../Loading/Loading";
import InProgress from "./InProgress";
import { useTheme } from "@mui/material/styles";
import Progress from "../Common/ProgressBar";
import { getCompletionPercentage } from "../../util/helpers/profileCompletionUtil";

const useStyles = makeStyles(() => ({
  large: {
    width: useTheme().spacing(12),
    height: useTheme().spacing(12),
  },
}));

function Dashboard({
  companyid,
  backend_url,
  setjobid,
  momatch_backend_url,
}) {
  const classes = useStyles();

  const [jobdata, setjobdata] = useState([]);
  const [company, setcompany] = useState("");
  const [companyData, setCompanyData] = useState("");
  const [free, setfree] = useState(false);
  const [username, setusername] = useState("");
  const [isloading, setisloading] = useState(false);
  const [disablejob, setdisablejob] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [profileCompletionPercentage, setProfileCompletionPercentage] =
    useState(0);
  const history = useHistory();
  useEffect(() => {
    const storePercentage = async () => {
      const percentage =
        (await getCompletionPercentage(
          companyid,
          backend_url,
          momatch_backend_url,
        )) ?? 0;
      setProfileCompletionPercentage(percentage);
    }
    storePercentage();
  }, [getCompletionPercentage]);

  useEffect(() => {
    if (companyid !== "") {
      setisloading(true);
      fetch(backend_url + "/company/find", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          _id: companyid,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setisloading(false);
            setCompanyData(data.result);
            setjobdata(data.result.Jobs.reverse());
            setcompany(data.result.company);
            setusername(data.result.name);
            if (data.result.category === "Free") {
              setfree(true);
              if (
                data.result.number_jobs_posted >= 10 &&
                data.result.Jobs.length >= 10
              ) {
                setdisablejob(true);
              }
            }
            if (data.result.category === "Subscription") {
              if (
                data.result.number_jobs_posted >= 5 &&
                data.result.Jobs.length >= 5
              ) {
                setdisablejob(true);
              }
            }
          }
        })
        .catch((e) => {
          setisloading(false);
          handleError(e);
        });
    } // eslint-disable-next-line
  }, [companyid]);

  if (isloading) {
    return (
      <React.Fragment>
        <div className="flex w-100 items-center justify-center bg-white ma3 br2 vh-75">
          <Loading text="Loading Dashboard" />
        </div>
      </React.Fragment>
    );
  }

  return (
    <div style={{ background: "#eef2f5" }} className="flex-1 w-100">
      {inProgress ? (
        <InProgress
          close={() => {
            setInProgress(false);
          }}
        />
      ) : null}
      <div className="w-90 center mb5">
        <div className="flex mt4 mb3 center justify-between w-90 items-center">
          <div className="flex flex-column justify-between ">
            <p className="ma0 f3-l f4-m f6">Welcome to {company} Dashboard!</p>
            <p className="f5-l hide f6-m f9-m gray ma0">
              Get candidates by our A.I system
            </p>
          </div>
          <button
            disabled={disablejob}
            onClick={() => history.push("/postjob")}
            style={{ background: "#6EB6FF" }}
            className={` ${
              disablejob ? "diso" : ""
            } c-shadow h2 pointer h7-mo fw6 f8-mo f7-m f6-l mr2 w-20-l w-20-m w4 bn link dim br2 ph3 pv2 dib white`}
          >
            {disablejob ? "Upgrade to Post Jobs" : "Post a Job"}
          </button>
        </div>
        <div className={`${free ? "" : "dn"}`}>
          <FreeBanner company={company} name={username} />
        </div>
        <div className="flex flex-row-l flex-column-reverse-m flex-column-reverse w-90-l w-90-m w-100 center ">
            <JobCardList
              jobs={jobdata}
              setjobid={setjobid}
              company={company}
              isPdl={companyData?.isPdl ?? false}
              companyid={companyid}
              momatch_backend_url={momatch_backend_url}
              setPopup={() => setInProgress(true)}
            />
          <div className="w1"></div>
          <div className="w-90 w-40-l ml-auto-l ml0-m ml0 self-start-l self-center-m self-center">
            { (profileCompletionPercentage < 100 && profileCompletionPercentage > 0) && (
              <div
                style={{ height: "max-content" }}
                className="flex flex-column justify-around items-start ma3 mr0 pa3 br2 bg-white c-shadow"
              >
                <h4 className="gray f6 f6-m ma0">Complete your profile</h4>
                <Progress done={profileCompletionPercentage} />
                <div>
                  <p className="gray f7-l f7-m f8-mo b">
                    Possible Pending Fields (Go to Settings to complete)
                  </p>
                  <ul className="pl3">
                    <li className="gray f7-l f7-m f8-mo">
                      Company Logo in Career Page Settings
                    </li>
                    <li className="gray f7-l f7-m f8-mo mt2">
                      About Company in Career Page Settings
                    </li>
                    <li className="gray f7-l f7-m f8-mo mt2">Work Culture in Career Page Settings</li>
                    <li className="gray f7-l f7-m f8-mo mt2">
                      Linkedin Company ID
                    </li>
                  </ul>
                </div>
              </div>
            )}
            <div
              style={{ height: "max-content" }}
              className="ma3 mr0 ph0-l pv3-l pa2 br2 bg-white c-shadow tc"
            >
              <p className="f4-l f4-m f5">Your talent manager</p>
              <p className="gray f5-l f5-m f6">Quirin Blendl</p>
              <Avatar
                alt="Alexa"
                src={quirin}
                className={`center ${classes.large}`}
              />
              <div className="flex flex-row-l flex-column justify-around mt4 items-center">
                <a href="mailto:quirin@moyyn.de" className="link gray pointer">
                  <button
                    style={{ width: "9rem" }}
                    className="bg-white pointer ml2-l ml2-m ml3 c-shadow f8-mo f8-m f7-l h2-l h2-m h7-mo bn link dim br2 ph3 pv2 dib tc"
                  >
                    Email
                  </button>
                </a>
                <a
                  href="https://calendly.com/book-quirin/30min"
                  style={{ color: "#6EB6FF" }}
                  className="link gray pointer"
                  rel="noopener"
                  target="_blank"
                >
                  <button
                    style={{ background: "#6EB6FF", width: "9rem" }}
                    className="c-shadow mt2 mt0-m ml2-l ml2-m ml3 pointer f8-mo f8-m f7-l mr2-l mr2-m mr0 h2-l h2-m h7-mo bn link dim br2 ph3 pv2 dib white"
                  >
                    Upgrade / Demo
                  </button>
                </a>
              </div>
            </div>
            <div
              style={{ height: "max-content" }}
              className="flex justify-around items-center ma3 mr0 pa3 br2 bg-white c-shadow tc"
            >
              <p className="gray f7-l f7-m f8-mo b">
                Checkout Your Job Dashboard{" "}
              </p>
              <a
                href={`https://jobs.moyyn.com/${company}?companyId=${jobdata[0]?.company}`}
                rel="noopener"
                target="_blank"
                style={{ background: "#6EB6FF", width: "9rem" }}
                className="c-shadow ml2-l ml2-m ml3 pointer f8-mo f8-m f7-l mr2-l mr2-m mr0 h2-l h2-m h7-mo bn link dim br2 ph3 pv2 dib white"
              >
                Career Page
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
