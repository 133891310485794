import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormError from "../../Common/FormError";
import { Field } from "formik";

const RadioList = ({ data }) => {
  return (
    <>
      {data.map(({ value, label }, idx) => (
        <FormControlLabel
          key={`${idx}-radio`}
          value={value}
          sx={{
            "&.MuiFormControlLabel-root": {
              color: "#2d2d2d",
            },
            ".MuiFormControlLabel-label": {
              fontSize: '0.8rem'
            }
          }}
          control={
            <Radio
              cssClass="e-small"
              sx={{
                "&.Mui-checked": {
                  color: "#6EB6FF",
                },
              }}
            />
          }
          label={label}
        />
      ))}
    </>
  );
};

export default function RadioButtonsGroup({ label, name, data, isRow = true }) {
  return (
    <Field name={name}>
      {({ form, field, meta }) => {
        const handleChange = (event, field) => {
          field.setFieldValue(name, event.target.value);
        };

        return (
          <>
            <FormControl>
              <FormLabel
                sx={{
                  "&.MuiFormLabel-root": {
                    color: "gray",
                  },
                }}
                id={`${name}-radio-btn`}
              >
                {label}
              </FormLabel>
              {isRow ? (
                <RadioGroup
                  row
                  aria-labelledby={`${name}-radio-btn`}
                  defaultValue={data?.[0]}
                  name={name}
                  onChange={(e) => handleChange(e, form)}
                  value={field.value}
                >
                  <RadioList data={data} />
                </RadioGroup>
              ) : (
                <RadioGroup
                  aria-labelledby={`${name}-radio-btn`}
                  defaultValue={data?.[0]}
                  name={name}
                  onChange={(e) => handleChange(e, form)}
                  value={field.value}
                >
                  <RadioList data={data} />
                </RadioGroup>
              )}
            </FormControl>
            <FormError name={name} />
          </>
        );
      }}
    </Field>
  );
}
