import React from "react";
import maintenance from "../../assets/lotties/maintenance.json";
import Lottie from "react-lottie";
import { useMediaQuery } from "react-responsive";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: maintenance,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function ErrorFallback({ resetErrorBoundary }) {
  const isMobile = useMediaQuery({ query: `(max-width: 580px)` });
  const dimensions = isMobile ? 280 : 420;
  return (
    <div className="flex flex-column justify-center items-center tc gray">
      <Lottie options={defaultOptions} height={dimensions} width={dimensions} />
      <h1 className={`${isMobile ? 'f4 ' : ''}`}>
        <span style={{color:"#6EB6FF"}} className="f1">500</span> <br /><br/>
        Something Went Wrong 
      </h1>
      <p>Don&apos;t Worry, We are currently trying to fix the problem.</p>
      <button
            onClick={resetErrorBoundary}
            style={{ color: "#6EB6FF" }}
            className="bg-white pointer f6 ml2-l ml2-m ml3 c-shadow h2-l mt2 mt0-l mt0-m bn link dim br2 ph3 pv2-l pv1 dib"
          >
            Try again
        </button>
    </div>
  );
}

export default ErrorFallback;
