import React from "react";
import { TextField } from "@mui/material";
import { Field } from "formik";
import FormError from "../../Common/FormError";

const FormikSelectMenu = ({ name, label, options }) => {
  return (
    <React.Fragment>
      <Field name={name}>
        {({ field, meta }) => {
          return (
            <TextField
              {...field}
              label={label}
              fullWidth
              select
              SelectProps={{
                native: true,
              }}
              variant="outlined"
            >
              {options.map((option, id) => (
                <option key={id} value={option.value}>
                  {option.name}
                </option>
              ))}
            </TextField>
          );
        }}
      </Field>
      <FormError name={name} />
    </React.Fragment>
  );
};

export default FormikSelectMenu;
