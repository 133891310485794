/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import "../../styles/candidate.scss";
import SignUpForm from "../CompanySignUp/SignUpForm";
import { signupforminitialvalues } from "../../util/data/initial-values";
import "tachyons";
import "../../styles/signup.scss";
import "../../styles/setting.scss";
import Credits from "./Billing/Credits";
import { Career } from "./Career Page/Career";
import { General } from "./General/General";
import { Credentials } from "./Credentails/Credentials";

function Settings({ companyid, setlogin, backend_url, momatch_backend_url, isPdl }) {
  const [edit_company, setedit_company] = useState(false);
  const [general_settings, setgeneral_settings] = useState(true);
  const [credits_page, setcredits_page] = useState(false);
  const [isCareerPage, setIsCareerPage] = useState(false);

  const [value, setValue] = useState(signupforminitialvalues);

  useEffect(() => {
    if (!edit_company && companyid) {
      fetch(backend_url + "/company/find", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          _id: companyid,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            if (data?.result?.get_to_know)
              data.result["getToKnow"] = data?.result?.get_to_know;
            if (data?.result?.number_jobs_required)
              data.result["howManyJobs"] = data?.result?.number_jobs_required;
            if (data?.result?.model_type)
              data.result["modelType"] = data?.result?.model_type;
            setValue(data.result);
          }
        });
    }
  }, [backend_url, companyid, edit_company]);

  const general = () => {
    setedit_company(false);
    setgeneral_settings(true);
    setcredits_page(false);
    setIsCareerPage(false);
  };

  const editcompany = () => {
    setedit_company(true);
    setgeneral_settings(false);
    setcredits_page(false);
    setIsCareerPage(false);
  };

  const credentials = () => {
    setedit_company(false);
    setgeneral_settings(false);
    setcredits_page(false);
    setIsCareerPage(false);
  };

  const credithistory = () => {
    setedit_company(false);
    setgeneral_settings(false);
    setIsCareerPage(false);
    setcredits_page(true);
  };

  const careerPage = () => {
    setedit_company(false);
    setgeneral_settings(false);
    setcredits_page(false);
    setIsCareerPage(true);
  };

  return (
    <div style={{ background: "#eef2f5" }} className="flex-1 w-100">
      <div className="w-90 center">
        <p className="gray tc f2 mb0">Settings</p>
        <div
          style={{ borderColor: "rgb(249, 246, 246)" }}
          className="flex self-start justify-start pt4 ml2 pb1"
        >
          <Button
            onClick={general}
            variant="contained"
            className={`cbtn ${general_settings ? "cbtn-active" : ""}`}
          >
            General
          </Button>
          <Button
            onClick={editcompany}
            variant="contained"
            className={`cbtn ${edit_company ? "cbtn-active" : ""}`}
          >
            Credentials
          </Button>
          <Button
            onClick={credentials}
            variant="contained"
            className={`cbtn ${
              edit_company || general_settings || credits_page || isCareerPage
                ? ""
                : "cbtn-active"
            }`}
          >
            Details
          </Button>
          {isPdl && <Button
            onClick={credithistory}
            variant="contained"
            className={`cbtn ${credits_page ? "cbtn-active" : ""}`}
          >
            Billing
          </Button>}
          <Button
            onClick={careerPage}
            variant="contained"
            className={`cbtn ${isCareerPage ? "cbtn-active" : ""}`}
          >
            Career Page
          </Button>
        </div>
        {general_settings ? (
          // General settings
          <General
            backendUrl={backend_url}
            companyId={companyid}
            setLogin={setlogin}
          />
        ) : edit_company ? (
          // credentials
          <Credentials backendUrl={backend_url} companyId={companyid} />
        ) : credits_page ? (
          <div className='mt4 pa4 bg-white br2'>
            <Credits
              companyId={companyid}
              momatchBackendUrl={momatch_backend_url}
            />
          </div>
        ) : isCareerPage ? (
          <Career
            companyId={companyid}
            momatchBackendUrl={momatch_backend_url}
          />
        ) : (
          // edit company details
          <div className="mv4 pa3 w-95 center flex-1 tc bg-white">
            <p className="gray tc f3">Edit Details</p>
            <div className="center signupform w-80 ">
              <SignUpForm
                signupforminitialvalues={value}
                backend_url={backend_url}
                companyid={companyid}
                editcompany={true}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Settings;
