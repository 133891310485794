import React from "react";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import "tachyons";

export default function FreeTrialPrompt({ name, company, isLoadingBanner, isTalentPoolPage }) {
  return (
    <div
      style={{ borderLeft: "2px solid #f9e31e" }}
      className={`bg-white br2 w-90 center mv4 c-shadow`}
    >
      <div className="ph3 pv4 flex justify-around items-center">
        <div className="flex flex-column" style={{gap: "5px"}}>
          {isLoadingBanner&&<p className="ma0 f7 " style={{ color: "#6EB6FF" }}>Wondering why you only see 3 candidates?</p>}
          <p className="flex-4 f6-l f6-m f8-mo gray ma0 tc">
            {isLoadingBanner
              ? `You're using a free trial plan, to upgrade please send us a mail`
              : isTalentPoolPage ? 'To get unlimited access to talent pool candidates, upgrade to paid model'
                :`If you would like to access our talent pool, AI Matchmaking or post more jobs, send us an email to get upgraded`}
          </p>
        </div>
        <button
          onClick={(e) => {
            window.location.href = `mailto:quirin@moyyn.com, arvi@moyyn.com?subject=Moyyn - Upgrade&body=Hello,%0d%0a %0d%0a I am ${name} from ${company} and I would be interested to know more about premium plans of Moyyn. Please let me know the further steps.%0d%0a %0d%0a Best regards,%0d%0a ${company}%0d%0a ${name}`;
            e.preventDefault();
          }}
          style={{ color: "#6EB6FF" }}
          className="bg-white pointer ml2-l ml2-m ml3 w-30-l w4 c-shadow h2-l mt2 mt0-l mt0-m bn link dim br2 ph3 pv2-l pv1 dib"
        >
          <div className="btnic flex f9-mo f8-m f7-l f8-m  items-center justify-center">
            <MailOutlinedIcon />
            <span className="ml2">Send Mail</span>
          </div>
        </button>
      </div>
    </div>
  );
}
