export const getCompletionPercentage = async (
  companyId,
  backend_url,
  momatchBackendUrl,
) => {
  if (!companyId) return;

  const companyData = await (
    await fetch(backend_url + "/company/find", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        _id: companyId,
      }),
    })
  ).json();
  const careerPageData = await (
    await fetch(momatchBackendUrl + "/company/page/fetch", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        companyId: companyId,
      }),
    })
  ).json();

  let availableFieldsCount = 0;

  if (companyData.result.linkedin_company_id) availableFieldsCount++;
//   if (companyData.result.calender_link) availableFieldsCount++;
//   if (companyData.result.linkedin_company_id) availableFieldsCount++;
  if (careerPageData.logo) availableFieldsCount++;
  if (careerPageData.about && careerPageData.about !== "<p><br></p>")
    availableFieldsCount++;
  if (careerPageData.benefits && careerPageData.benefits !== "<p><br></p>")
    availableFieldsCount++;

  const percentage = (availableFieldsCount / 4) * 100;

  return percentage.toFixed(0);
};
