import React, { useState, useEffect } from "react";
import ToggleButton from "react-toggle-button";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useErrorHandler } from "react-error-boundary";

export const General = ({ backendUrl, companyId, setLogin }) => {
  const history = useHistory();
  const handleError = useErrorHandler();

  const [deleteAccount, setDeleteAccount] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (confirmDelete) {
      fetch(backendUrl + "/company/delete", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          _id: companyId,
          timestamp: new Date(),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setLogin(false);
            localStorage.setItem("loggedin", false);
            history.push("/");
          }
        })
        .catch((err) => handleError(err));
    } // eslint-disable-next-line
  }, [confirmDelete]);

  useEffect(() => {
    if (clicked) {
      fetch(backendUrl + "/company/updateNotification", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          _id: companyId,
          notification: toggle,
          timestamp: new Date(),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.sucess) {
            console.log("notification changed!");
          }
        });
    }
  }, [toggle, companyId, backendUrl, clicked]);

  useEffect(() => {
    if (companyId !== "") {
      fetch(backendUrl + "/company/checkNotification", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          _id: companyId,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setToggle(data.result.notification);
          }
        })
        .catch((err) => handleError(err));
    }
  }, [companyId, backendUrl]);

  return (
    <div className="mv4 pa4 w-95 center flex-1 tc bg-white">
      <div
        onClick={() => setDeleteAccount(false)}
        className={`${
          deleteAccount ? "" : "hide"
        } fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center`}
      >
        <div className="h5 w-50 flex justify-center items-center bg-white c-shadow br2">
          <div className="w-100">
            <p className="gray f5-l f6-m f7">
              All your data will be deleted. Are you Sure?
            </p>
            <div className="flex center justify-around w-80">
              <Button
                onClick={() => setConfirmDelete(true)}
                className="dim flex-1"
                variant="outlined"
                style={{ borderColor: "red", color: "red" }}
              >
                Confirm Delete
              </Button>
              <div className="w2"></div>
              <Button
                onClick={() => setDeleteAccount(false)}
                className="dim flex-1"
                variant="outlined"
                style={{ borderColor: "#6EB6FF", color: "#6EB6FF" }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
      <p className="gray tc f3">General Settings</p>
      {!deleteAccount && (
        <div className="flex items-center mt4 justify-center">
          <p className="gray f5-l f6-m f7 ma0 mr3">
            Turn {toggle ? "off" : "on"} {`notification's`} ?
          </p>
          <div className="toggle-btn">
            <ToggleButton
              value={toggle}
              onToggle={() => {
                setClicked(true);
                setToggle(!toggle);
              }}
            />
          </div>
        </div>
      )}
      {!deleteAccount && (
        <div className="mt4">
          <Button
            onClick={() => setDeleteAccount(true)}
            className="dim"
            variant="outlined"
            style={{ borderColor: "red", color: "red" }}
          >
            Delete Account
          </Button>
        </div>
      )}
    </div>
  );
};
