import React from "react";
import { makeStyles } from "@mui/styles";
import { Backdrop } from "@mui/material";
import { useTheme } from '@mui/material/styles';


const useStyles = makeStyles(() => ({
  img: {
    width: "98vh",
    height: "98vh",
  },
  backdrop: {
    zIndex: useTheme().zIndex.drawer + 1,
    color: "#fff",
  },
}));
const ResumePreview = ({ open, src, close }) => {
  const custom = useStyles();

  return (
    <Backdrop className={custom.backdrop} open={open} onClick={close}>
      <embed title="CV Preview" src={src} className={custom.img} />
    </Backdrop>
  );
};

export default ResumePreview;
