export const postjobinitialvalues = {
  jobTitle: "",
  jobType: "",
  description: "",
  requirements: "",
  country: "",
  city: "",
  state: "",
  postcode: "",
  careerLevel: "",
  Industries: [],
  Skills: [],
  skills_secondary: [],
  jobCategory: "",
  workExperience: [],
  Languages: [],
  Date: "",
  flexibleDate: "",
  currency: "",
  from: "",
  to: "",
  otherCountries: false,
  credits: "",
  relevantJobTitle: [],
};

export const signupforminitialvalues = {
  company: "",
  name: "",
  website: "",
  location: "",
  email: "",
  phone: "",
  password: "",
  linkedin_company_id: "",
  calender_link: "",
  modelType: "",
  getToKnow: "",
  howManyJobs: "",
  signupforminitialvalues: "",
};

export const careerPageFormInitialValues = {
  about: "",
  benefits: "",
  workCulture: "",
};

export const resetforminitialvalue = {
  otp: "",
  password: "",
  confirmpassword: "",
};
