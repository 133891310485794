import React from "react";
import JobCard from "./Jobcard";
import { useHistory } from "react-router-dom";

function Jobcardlist({
  jobs,
  setjobid,
  momatch_backend_url,
  setPopup,
  company,
  isPdl
}) {
  const history = useHistory();
  return (
    <div className="w-100 flex center flex-column ma-2">
      {jobs.length <= 0 || jobs[0] === undefined ? (
        <div
          className="flex flex-column justify-start items-center"
        >
          <div className="gray tl">
            <p className="tc">-- No jobs posted yet --- </p>
            <p>Wondering what to do next?</p>
            <ol className="ol-list">
              <li>Click on &apos;post a job&apos;</li>
              <li>
                Fill in the required information. The better you describe your
                job, the more interest your job will spark
              </li>
              <li>
                Moyyn will post your job with the next 24h on +10 Platforms like
                Google, Linkedin, Indeed, and others.
              </li>
              <li>Check your Account regularly.</li>
            </ol>
          </div>
          <button
            onClick={() => history.push("/postjob")}
            style={{ background: "#6EB6FF" }}
            className={`c-shadow h2 pointer h7-mo fw6 f8-mo f7-m f6-l mr2 w-20-l w-20-m w4 bn link dim br2 ph3 pv2 dib white`}
          >
            Post a Job
          </button>
        </div>
      ) : (
        jobs.map((jobdata, id) => (
          <JobCard
            job={jobdata}
            setjobid={setjobid}
            backend_url={momatch_backend_url}
            key={id}
            isPdl={isPdl}
            setPopup={setPopup}
            company={company}
          />
        ))
      )}
    </div>
  );
}

export default Jobcardlist;
