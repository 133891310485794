import React, { useState, useEffect } from "react";
import { Button, Snackbar } from "@mui/material";
import TextField from "@mui/material/TextField";
import MuiAlert from "@mui/material/Alert";
import { useErrorHandler } from "react-error-boundary";

export const Credentials = ({ backendUrl, companyId }) => {
  const [passwordUpdate, setPasswordUpdate] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [resetPassword, setResetPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);

  const handleError = useErrorHandler();

  useEffect(() => {
    if (passwordUpdate) {
      fetch(backendUrl + "/company/updatePassword", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          _id: companyId,
          old_password: oldPassword,
          new_password: newPassword,
          timestamp: new Date(),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setResetPassword(true);
            setErrorMessage(data.message);
          } else {
            setShowError(true);
            setErrorMessage(data.message[0].message[0]);
          }
        })
        .catch((err) => handleError(err));
      setPasswordUpdate(false);
    }
  }, [passwordUpdate, backendUrl, companyId, newPassword, oldPassword]);

  return (
    <div className="mv4 pa3 w-95 center flex-1 tc bg-white">
      <p className="gray tc f3">Credentials</p>
      <div>
        <div className="creds mt3">
          <TextField
            name="Old"
            label="Old Password"
            variant="outlined"
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            className={`w-50-l w-70-m w-80`}
          />
        </div>
        <div className="creds mt3">
          <TextField
            name="new"
            label="New Password"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            variant="outlined"
            className={`w-50-l w-70-m w-80`}
          />
        </div>
        <div className="mt4">
          <Button
            className="dim"
            onClick={() => setPasswordUpdate(true)}
            variant="outlined"
            style={{ borderColor: "#6EB6FF", color: "#6EB6FF" }}
          >
            Update Password
          </Button>
        </div>
      </div>
      <Snackbar
        open={resetPassword || showError}
        autoHideDuration={5000}
        onClose={() => {
          setResetPassword(false);
          setShowError(false);
        }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => {
            setResetPassword(false);
            setShowError(false);
          }}
          severity={showError ? "error" : "success"}
        >
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};
