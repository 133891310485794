/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "tachyons";
import "../../styles/header.scss";
import Logo from "../../svg/Logo.png";

function DashboardHeader({ logout, isPdl }) {
  const logoutvalues = () => {
    logout(false);
    localStorage.setItem("loggedin", JSON.stringify(false));
    localStorage.setItem("c_id", JSON.stringify({}));
    localStorage.setItem("j_id", JSON.stringify({}));
    localStorage.removeItem("email");
    localStorage.removeItem("j_token");
  };
  const path = useLocation().pathname;
  let [burgerclicked, setburger] = useState(false);
  return (
    <div className="header w-100 h3">
      <div className="flex justify-end headflex items-center w-90 center">
        <div className="logoheader w4">
          <a className="link" href="https://client.moyyn.com">
            <img className="Logo" src={Logo} alt="Moyyn" />
          </a>
        </div>
        <div
          className={`navbar${
            burgerclicked ? "nav-active" : ""
          }`}
        >
          <ul className="flex nav w-90 navlist pa0 ma0 items-center">
            <li className="mh4 list fw5 f7 f6-l">
              <Link className="link gray dim dib" to="/dashboard">
                <p>Dashboard</p>
              </Link>
            </li>
            {isPdl && (
              <li className="mh4 list fw5 f7 f6-l">
                <Link className="link gray dim dib" to="/credits">
                  <p>Credits</p>
                </Link>
              </li>
            )}
            <li className="mh4 list fw5 f7 f6-l">
              <Link className="link gray dim dib" to="/settings">
                <p>Settings</p>
              </Link>
            </li>
          </ul>
        </div>
        <div onClick={logoutvalues} className="dicon">
          <Link style={{ color: "#6EB6FF" }} to="/" className="gray">
            <i className="fa fa-sign-out"></i>
          </Link>
        </div>
        <div
          className={`burger mb2 ${burgerclicked ? "toggle" : ""}`}
          onClick={() => {
            setburger((burgerclicked = !burgerclicked));
          }}
        >
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </div>
    </div>
  );
}

export default DashboardHeader;
