/* eslint-disable dot-notation */
import React, { useEffect, useState } from "react";
import JobForm from "./JobForm";
import { postjobinitialvalues } from "../../util/data/initial-values";
import Loading from "../Loading/Loading";
import { useErrorHandler } from "react-error-boundary";

function EditJob({
  backend_url,
  jobid,
  setjobid,
  momatch_backend_url,
  companyid,
}) {
  const [value, setvalue] = useState(postjobinitialvalues);
  const [loading, setloading] = useState(false);
  const [status, setstatus] = useState("");
  const [companyData, setCompanyData] = useState(null);
  const handleError = useErrorHandler()

  useEffect(() => {
    if (companyid === "") return;
    fetch(backend_url + "/company/find", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        _id: companyid,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setCompanyData(data.result);
          setstatus(data.result.status);
        }
      });
  }, [companyid, backend_url]);

  useEffect(() => {
    if (jobid !== "") {
      setloading(true);
      fetch(backend_url + "/job/find", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          _id: jobid,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            if (!data.result.relevantJobTitle) data.result["relevantJobTitle"] = [];
            if (!data.result.jobType) data.result["jobType"] = "";
            setvalue(data.result);
            setloading(false);
          }
        })
        .catch((err) => {
          setloading(false);
          console.error("can't fetch job value's");
          handleError(err);
        });
    }
    setjobid(JSON.parse(localStorage.getItem("j_id"))); // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <React.Fragment>
        <div className="flex items-center w-80 justify-center bg-white ma3 br2 vh-75">
          <Loading text="Loading Job Values" />
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className="w-100">
      <JobForm
        backend_url={backend_url}
        status={status}
        companyData={companyData}
        momatch_backend_url={momatch_backend_url}
        postjobinitialvalues={value}
        editjob={true}
        jobid={jobid}
      />
    </div>
  );
}

export default EditJob;
