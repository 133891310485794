const url = "http://138.197.189.222";
export const cv_prefix = "https://spaces.moyyn.com/englishCVs/";
export const cv_prefix_gr = "https://spaces.moyyn.com/germanCVs/";
export const cv_def = "https://spaces.moyyn.com/Moyyn_CVs/nocv.pdf";
export const clientBackendURI = 'https://clientbackend.moyyn.com/api';

export const sendRequest = async (endpoint = "", method = "GET", body) => {
  const response = await fetch(`${url}${endpoint}`, {
    mode: "cors",
    method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  });
  const data = await response.json();

  return data;
};

export const GetData = async (endpoint = "", method = "GET") => {
  const response = await fetch(`${url}${endpoint}`, {
    mode: "cors",
    method,
    headers: { "Content-Type": "application/json" },
  });
  let data = await response.json();
  data = JSON.parse(data);
  return data;
};

export const myErrorHandler = async (error, info) => {
  if (process.env.REACT_APP_At === "prod") {
    await fetch(`${clientBackendURI}/bug/report`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        error: error,
        companyId: JSON.parse(localStorage.getItem('c_id')),
        path: window.location.pathname,
        description: info?.componentStack,
        platform: 'client'
      })
    });
  }
};   