import React, { useState, useEffect } from "react";
import FormError from "../../Common/FormError";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import {
  careerLevelOptions,
  category,
  recruit,
  cities as city,
  countries as countriesArray,
} from "../../../util/data/static-data";
import SelectMenu from "../Formelements/SelectMenuForm";
import WorkExperience from "../Formelements/WorkExperience";
import Languages from "../Formelements/Languages";
import PDLFetchField from "../Formelements/PDLFetchField";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Formik } from "formik";
import { useMediaQuery } from "react-responsive";
import { Postjobstep2formvalidation } from "../../../util/validation/form-validation";
import Box from '@mui/material/Box';

export default function Step2({
  initailFormValues,
  editjob,
  handlePrevStep,
  handleFormChange,
}) {
  const [hov1, sethov1] = useState(false);
  const [hov2, sethov2] = useState(false);
  const [hov3, sethov3] = useState(false);
  const [hov4, sethov4] = useState(false);
  const ismobile = useMediaQuery({ query: `(max-width: 580px)` });
  const [country, setcountry] = useState(initailFormValues.country);
  const [states, setstates] = useState([]);
  const [cities, setcities] = useState([{ name: "" }]);

  useEffect(() => {
    if (!country) return;
    fetch(`https://countriesnow.space/api/v0.1/countries/states/q?country=${country}`)
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setstates(data.data.states);
        }
      });

    fetch(`https://countriesnow.space/api/v0.1/countries/cities/q?country=${country}`)
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setcities(data.data);
        } else setcities(city);
      })
      .catch(() => setcities(city));
  }, [country]);

  return (
    <Formik
      initialValues={initailFormValues}
      enableReinitialize={true}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        handleFormChange(values, true, true);
        setSubmitting(true);
      }}
      validationSchema={Postjobstep2formvalidation}
    >
      {(props) => {
        const {
          isSubmitting,
          values, // eslint-disable-line
          handleChange, // eslint-disable-line
          handleBlur, // eslint-disable-line
          handleSubmit, // eslint-disable-line
          setFieldValue,
        } = props;
        setcountry(values.country);

        return (
          <>
            <div className="w-80-l w-90 w-80-m mt2-l mb2-l mt1 mb1">
              <div className="mt3">
                <SelectMenu
                  className="flex-1"
                  name="careerLevel"
                  label="Career level"
                  options={careerLevelOptions}
                />
              </div>
              <div className="mt3">
                <SelectMenu
                  className="flex-1"
                  name="jobCategory"
                  label="Job Category"
                  options={category}
                />
              </div>
              <div className="mt3 relative">
                <WorkExperience />
                {!ismobile ? (
                  <div>
                    <div
                      style={{ background: "#eef2f5" }}
                      className={`${
                        hov1 ? "" : "hide"
                      } flex justify-center items-center c-shadow tc h3 w5 br2 absolute gray f7 z-11 bottom--2 right--2`}
                    >
                      Please select the job roles relevant to your offer to
                      filter the best candidates
                    </div>
                    <div
                      onMouseLeave={() => sethov1(false)}
                      onMouseOver={() => sethov1(true)}
                      style={{ color: "#6EB6FF" }}
                      className="pointer help-ico dim bottom-2 mb2 absolute right--2"
                    >
                      <HelpOutlineOutlinedIcon />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="mt3 relative">
                <Languages />
                {!ismobile ? (
                  <div>
                    <div
                      style={{ background: "#eef2f5" }}
                      className={`${
                        hov2 ? "" : "hide"
                      } flex justify-center items-center c-shadow tc h3 w5 br2 absolute gray f7 z-11 bottom--1 right--2`}
                    >
                      {`A1, A2 -> Beginner level |  B1,B2 -> Intermediate level | C1,C2 -> Fluent`}
                    </div>
                    <div
                      onMouseLeave={() => sethov2(false)}
                      onMouseOver={() => sethov2(true)}
                      style={{ color: "#6EB6FF" }}
                      className="pointer help-ico dim top-1 mt1 absolute right--2"
                    >
                      <HelpOutlineOutlinedIcon />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="mt3 relative">
                <PDLFetchField
                  name="Skills"
                  placeholder={`Must have skills ${
                    !ismobile ? "(Please select max. 2 skills)" : ""
                  }`}
                  pdlName="skill"
                />
                {!ismobile ? (
                  <div>
                    <div
                      style={{ background: "#eef2f5" }}
                      className={`${
                        hov3 ? "" : "hide"
                      } flex justify-center items-center c-shadow tc h3 w5 br2 absolute gray f7 top-2 z-11 right--2`}
                    >
                      Please select maximum 2 skills associated with the job
                    </div>
                    <div
                      onMouseLeave={() => sethov3(false)}
                      onMouseOver={() => sethov3(true)}
                      style={{ color: "#6EB6FF" }}
                      className="pointer help-ico dim top-1 absolute right--2"
                    >
                      <HelpOutlineOutlinedIcon />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="mt3 relative">
                <PDLFetchField
                  name="skills_secondary"
                  placeholder={`Nice to have skills ${
                    !ismobile
                      ? "(Please select as many skills as possible)"
                      : ""
                  }`}
                  pdlName="skill"
                  mskill={false}
                />
                {!ismobile ? (
                  <div>
                    <div
                      style={{ background: "#eef2f5" }}
                      className={`${
                        hov4 ? "" : "hide"
                      } flex justify-center items-center c-shadow tc h3 w5 br2 absolute gray f7 top-2 z-11 right--2`}
                    >
                      Please select as many skills as possible associated with
                      the job
                    </div>
                    <div
                      onMouseLeave={() => sethov4(false)}
                      onMouseOver={() => sethov4(true)}
                      style={{ color: "#6EB6FF" }}
                      className="pointer help-ico dim top-1 absolute right--2"
                    >
                      <HelpOutlineOutlinedIcon />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="mt3">
                <SelectMenu
                  className="flex-1"
                  name="otherCountries"
                  label="Willing to recruit from"
                  options={recruit}
                />
              </div>
            </div>
            <div className="w-80-l w-90 w-80-m mt2-l mb2-l mt1 mb1 flex flex-row-l flex-row-m flex-column justify-between">
              <div className="mt3 mr0 mr2-m mr3-l w-100">
                {editjob ? (
                  <Autocomplete
                    options={countriesArray}
                    name="country"
                    autoHighlight
                    placeholder="Country"
                    value={values.country}
                    inputValue={values.country}
                    onInputChange={(event, newInputValue) => {
                      setFieldValue("country", newInputValue);
                    }}
                    getOptionLabel={(option) => option.label ? option.label : option}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label} ({option.code})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Job country"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                ) : (
                  <Autocomplete
                    options={countriesArray}
                    name="country"
                    placeholder="Country"
                    inputValue={values.country}
                    onInputChange={(event, newInputValue) => {
                      setFieldValue("country", newInputValue);
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.label ? option.label : option}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label} ({option.code})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Job country"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                )}
                <FormError name="country" />
              </div>

              {/* state */}

              <div className="mt3 w-100">
                {editjob ? (
                  <Autocomplete
                    options={states}
                    name="state"
                    placeholder="State"
                    disabled={!values.country}
                    value={values.state}
                    inputValue={values.state}
                    onInputChange={(event, newInputValue) => {
                      setFieldValue("state", newInputValue);
                    }}
                    autoHighlight
                    getOptionLabel={(option) =>
                      option.name ? option.name : option
                    }
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="state"
                        label="Job state"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                ) : (
                  <Autocomplete
                    options={states}
                    name="state"
                    placeholder="State"
                    disabled={!values.country}
                    inputValue={values.state}
                    onInputChange={(event, newInputValue) => {
                      setFieldValue("state", newInputValue);
                    }}
                    autoHighlight
                    getOptionLabel={(option) =>
                      option.name ? option.name : option
                    }
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="state"
                        label="Job state"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                )}
                <FormError name="state" />
              </div>
            </div>
            <div className="w-80-l w-90 w-80-m mt2-l mb2-l mt1 mb1 flex flex-row-l flex-row-m flex-column justify-between">
              <div className="mt3 mr0 mr2-m mr3-l w-100">
                {editjob ? (
                  <Autocomplete
                    options={cities}
                    name="city"
                    placeholder="City"
                    disabled={!values.country}
                    value={values.city}
                    inputValue={values.city}
                    onInputChange={(event, newInputValue) => {
                      setFieldValue("city", newInputValue);
                    }}
                    autoHighlight
                    getOptionLabel={(option) =>
                      option.city ? option.city : option
                    }
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="city"
                        label="Job city"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                ) : (
                  <Autocomplete
                    options={cities}
                    name="city"
                    placeholder="City"
                    disabled={!values.country}
                    inputValue={values.city}
                    onInputChange={(event, newInputValue) => {
                      setFieldValue("city", newInputValue);
                    }}
                    autoHighlight
                    getOptionLabel={(option) =>
                      option.city ? option.city : option
                    }
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="city"
                        label="Job city"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                )}
                <FormError name="city" />
              </div>
              <div className={`mt3 w-100 tf`}>
                <TextField
                  name="postcode"
                  id="outlined-basic"
                  label="Job post code"
                  variant="outlined"
                  value={values.postcode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="w-100"
                />
                <FormError name="postcode" />
              </div>
            </div>
            <div className="w-100 flex flex-row justify-center mt3">
              <button
                onClick={() => handlePrevStep()}
                style={{ background: "#555A60", height: "40px" }}
                className="mh2 pointer fw6 f7 f6-l w-20-l w-30 bn link dim br1 ph3 pv1 ph4-m pv2-m  pv2-l dib white bg-white"
              >
                Back
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                style={{ background: "#6EB6FF", height: "40px" }}
                onClick={() => handleSubmit()}
                className="mh2 pointer fw6 f7 f6-l w-20-l w-30 bn link dim br1 ph3 pv1 ph4-m pv2-m  pv2-l dib white bg-white"
              >
                Submit
              </button>
            </div>
          </>
        );
      }}
    </Formik>
  );
}
