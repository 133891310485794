import React, { useState, useEffect } from "react";
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Commentbox from "../Commentbox";
import ResumePreview from "../ResumePreview";
import { useMediaQuery } from "react-responsive";
import Tooltip from "@mui/material/Tooltip";
import {
  cv_def,
  cv_prefix,
  cv_prefix_gr,
} from "../../../util/helpers/helper-methods";

export const Matched = React.memo(
  ({
    appliedtag,
    rectag,
    mo_backend_url,
    candidate,
    jobid,
    jobcode,
    setupdate,
    shortlist,
    reject,
    k,
    popup,
    withtag,
    job_name,
    company,
    isAskedToApplyTag,
  }) => {
    const [isPreviewOpen, setisPreviewOpen] = useState(false);
    const ismobile = useMediaQuery({ query: `(max-width: 580px)` });
    // const {cv} = candidate;

    const [lang, setlang] = useState([]);
    const [candidateid, setcandidateid] = useState("");
    const [isAskToApplyClicked, setIsAskToApplyClicked] = useState(false);
    const [job_code] = useState(jobcode);
    const [cv, setcv] = useState("");

    const [dim, setdim] = useState(false);

    useEffect(() => {
      setcandidateid(candidate._id);
      if (lang.length <= 0) {
        candidate.languages.map((val) =>
          setlang((lang) => [...lang, val.language + "( " + val.level + " )"]),
        );
      }
      if (isAskToApplyClicked && job_code !== "" && job_code !== undefined) {
        setdim(true);
        fetch(mo_backend_url + "/candidates/ask", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            timestamp: new Date(),
            job_id: jobid,
            job_code: job_code,
            candidate_id: candidateid,
            cname: candidate.firstName + " " + candidate.lastName,
            to: candidate.email,
            job: job_name,
            company: company,
            job_link: `https://jobs.moyyn.com/${company}/${encodeURIComponent(job_name)}-${encodeURIComponent(job_code)}?jobType=Direct%20Client&jobid=${jobid}`
          }),
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {
              setupdate(true); 
            }
          });
      }
    }, [
      jobid,
      candidateid,
      isAskToApplyClicked,
      mo_backend_url,
      job_code,
      candidate,
      lang,
      setupdate,
      job_name,
    ]);

    useEffect(() => {
      let cvv = "";
      if (!candidate.cv.german && !candidate.cv.english) {
        cvv = cv_def;
      } else if (candidate.cv.english) {
        cvv = cv_prefix + candidate.cv.filename;
      } else {
        cvv = cv_prefix_gr + candidate.cv.filename;
      }
      setcv(cvv);
    }, [candidate]);

    return (
      <div
        key={k}
        style={{
          borderRadius: "6px",
          borderLeft: dim
            ? isAskToApplyClicked
              ? "16px solid #078307"
              :  ""
            : "3px solid #6EB6FF",
        }}
        className={`${
          dim ? "" : "dn"
        } w-100 relative ma1 dib ch bg-white pa3 mt3 br4}`}
      >
        <ResumePreview
          open={isPreviewOpen}
          close={() => setisPreviewOpen(!isPreviewOpen)}
          src={`${cv}#toolbar=0`}
        />
        <div className="flex justify-between items-start mt2-l mt0">
          <div className="flex flex-1 items-center">
            <div className="flex w-100 name_candidatebox flex-column">
              <p className="truncate ma0 f4-l f5-m f7">
                {candidate.firstName + " " + candidate.lastName}
              </p>
              <p
                style={{ color: "#6EB6FF" }}
                className="ma0 gray f7-l mt1 f7-m f8-mo"
              >
                {candidate.careerLevel}
              </p>
              <Tooltip title={lang.join(", ")}>
                <p className="truncate ma0 mt2_2 gray f8 f9-m f10-mo mt1">
                  {lang.join(", ")}
                </p>
              </Tooltip>{" "}
            </div>
          </div>
          <div
            className={`flex flex-1 flex-column items-start ${
              ismobile ? "hide" : ""
            }`}
          >
            {candidate.skills && (
              <p
                style={{ textTransform: "capitalize" }}
                className="truncate ma0 flex-1 mb3 gray f7 f8-m f8-mo"
              >
                Skills:{" "}
                {`${candidate.skills.filter((_, i) => i < 3).join(", ")}`}
              </p>
            )}
            {candidate.industries && (
              <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo truncate">{`Industry: ${`${candidate.industries
                .filter((_, i) => i < 2)
                .join(", ")}`}`}</p>
            )}
            {(candidate.city || candidate.country) && (
              <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo truncate">{`Location: ${`${candidate.city}, ${candidate.country}`}`}</p>
            )}
          </div>
          <div className="flex-1 flex justify-around items-center">
            <div
              onClick={() =>
                !popup
                  ? navigator.userAgent.indexOf("Chrome") !== -1 &&
                    navigator.vendor.indexOf("Google Inc") !== -1
                    ? window.open(
                        `${cv}`,
                        "_blank",
                        "toolbar=no,scrollbars=yes,resizable=no,top=500,left=500,width=650,height=800",
                      )
                    : setisPreviewOpen((prev) => !prev)
                  : {}
              }
              className="ico mb2 pointer flex flex-column items-center self-center"
            >
              <div style={{ color: "gray" }}>
                <DescriptionOutlinedIcon />
              </div>
              <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">View CV</p>
            </div>
            <Commentbox
              candidateid={candidateid}
              jobid={jobid}
              mo_backend_url={mo_backend_url}
              popup={popup}
            />
          </div>
          <div className="h-100 flex-1 w-25-l w-40-m w-30 pr4 mb2 mt3-l">
            <div className="flex justify-center flex-row-l flex-row-m flex-column justify-center mt1 items-center">
              <button
                onClick={() => (!popup ? setIsAskToApplyClicked(true) : {})}
                style={{ color: "green", opacity:`${(isAskedToApplyTag || isAskToApplyClicked)?'40%':'100%'}` }}
                disabled={(isAskedToApplyTag || isAskToApplyClicked)}
                className="bg-white c-shadow ml2-l ml2-m ml3 pointer mr2-l mr2-m mr0 w-100-l w-50-m w3 h2-l bn link dim br2 ph3 pv2-l pv1 dib white"
              >
                <div className=" btnic flex f8-m f7-l f9-mo f8-m items-center justify-center">
                  <MailOutlineOutlinedIcon />
                  <span className="ml2">{`${(isAskedToApplyTag || isAskToApplyClicked)? 'Already Asked' : `Ask ${ismobile ? '' : 'to Apply'}`}`}</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <p
          className={`${dim ? "absolute left-1 ma0 fw6 pl1 pr1 br2 f8" : "dn"}`}
          style={{
            border: `1px solid ${
              dim
                ? isAskToApplyClicked
                  ? "green"
                  : ""
                : "#6EB6FF"
            }`,
            color: dim
              ? isAskToApplyClicked
                ? "green"
                : ""
              : "#6EB6FF",
            top: "0.5rem",
          }}
        >
          {dim
            ? isAskToApplyClicked
              ? "Asked to Apply"
              : ""
            : "Recommended by Moyyn"}
        </p>
        <p
          className={`absolute ma0 fw6 pl1 pr1 br2 f8`}
          style={{
            border: `1px solid ${
              withtag ? "red" : appliedtag || rectag ? "green" : "#6EB6FF"
            }`,
            color: `${
              withtag ? "red" : appliedtag || rectag ? "green" : "#6EB6FF"
            }`,
            top: "0.5rem",
            left: `${dim ? (rectag ? "7.2rem" : "6.8rem") : "1rem"}`,
          }}
        >
          {withtag
            ? "Withdrawn"
            : appliedtag
            ? "Applied"
            : rectag
            ? "Recommended by Moyyn"
            : isAskedToApplyTag 
            ? "Asked To Apply"
            : "Talent Pool"}
        </p>
        <p className="absolute top-1 right-1 ma0 br2 gray f8 f9-m f10-mo">
              Registered on{" "}
              {new Date(candidate.createdAt).toLocaleDateString()}
            </p>
      </div>
    );
  },
);

export default Matched;
