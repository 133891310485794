import React, { useState, useEffect } from "react";
import "../styles/App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import RegistrationConformation from "../Components/RegistrationConformation/RegistrationConformation";
import "tachyons";
import Signup from "../Components/CompanySignUp/SignUp";
import Footer from "../Components/Footer/footer";
import Header from "../Components/Header/header";
import Dashboard from "../Components/Dashboard/Dashboard";
import DashboardHeader from "../Components/Header/DashboardHeader";
import JobPostForm from "../Components/PostJob/JobForm";
import Settings from "../Components/Settings/Settings";
import Candidates from "../Components/Candidates/Candidateslist";
import {
  postjobinitialvalues,
  signupforminitialvalues,
} from "../util/data/initial-values";
import EditJob from "../Components/PostJob/EditJob";
import ResetEmail from "../Components/Reset Password/EmailtoReset";
import ResetPass from "../Components/Reset Password/Resetpass";
import Login from "../Components/Login/Login";
import CreditManagement from "../Components/CreditManagement/CreditManagement";

function App() {
  const backend_url = process.env.REACT_APP_NEST;
  const momatch_backend_url = process.env.REACT_APP_BACKEND;

  const [registered, setregistered] = useState(false);
  const [loggedin, setloggedin] = useState(false);

  const [companyid, setcompanyid] = useState("");
  const [jobid, setjobid] = useState("");
  const [jobcode, setjobcode] = useState("");
  const [token, setToken] = useState(null);

  const [maildata, setmaildata] = useState({
    company: "",
    username: "",
    jobtittle: "",
    joblink: "",
    city: "",
    country: "",
    level: "",
    jobcode: "",
  });

  const [data] = useState([]);
  const [isPdl, setIsPdl] = useState(false);

  useEffect(() => {
    if (companyid !== "") {
      fetch(backend_url + "/company/find", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          _id: companyid,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setIsPdl(data.result.isPdl);
          }
        })
        .catch((e) => {
          console.log('error', e)
        });
    } // eslint-disable-next-line
  }, [companyid]);


  // eslint-disable-next-line
  useEffect(() => {
    setloggedin(JSON.parse(localStorage.getItem("loggedin")));
    setcompanyid(JSON.parse(localStorage.getItem("c_id")));
    setjobid(JSON.parse(localStorage.getItem("j_id")));
    setjobcode(JSON.parse(localStorage.getItem("j_code")));
    // setToken(JSON.parse(localStorage.getItem("j_token")));
  }, []);

  useEffect(() => {
    if (companyid === "") return;
    fetch(`${momatch_backend_url}/admin/sitelive`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        _id: "dfldmfkl7y7dfndufh78dfbdf8ndkjnnn889943",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          if (
            res.data[0].ClientLive !== null &&
            res.data[0].CandidateLive !== null
          ) {
            if (res.data[0].ClientLive === false)
              window.location = "https://moyyn.com/scheduled-maintenance";
          }
        }
      });
  }, [companyid]);

  return (
    <div
      style={{ background: "#eef2f5", minHeight: "100vh" }}
      className="App w-100 flex flex-column items-center"
    >
      <Router>
        <Switch>
          <Route exact path="/">
            {loggedin ? <Redirect to="/dashboard" /> : ""}
            <div className="bg-white">
              <Header />
              <Signup
                companyid={companyid}
                backend_url={backend_url}
                registered={registered}
                setregistered={setregistered}
                editcompany={true}
                signupforminitialvalues={signupforminitialvalues}
              />
              <Footer />
            </div>
          </Route>
          <Route exact path="/login">
            {loggedin ? <Redirect to="/dashboard" /> : ""}
            <div className="bg-white w-100">
              <Header />
              <Login
                setlog={setloggedin}
                setcompanydata={setcompanyid}
                backend_url={backend_url}
                setToken={setToken}
            />
            <Footer />

            </div>
          </Route>
          <Route exact path="/confirmation">
            {registered ? "" : <Redirect to="/" />}
            <Header />
            <RegistrationConformation />
            <Footer />
          </Route>
          <Route exact path="/dashboard">
            {loggedin ? "" : <Redirect to="/" />}
            <DashboardHeader isPdl={isPdl} logout={setloggedin} />
            <Dashboard
              momatch_backend_url={momatch_backend_url}
              setcompanyid={setcompanyid}
              setjobid={setjobid}
              backend_url={backend_url}
              companyid={companyid}
              isCreditPage={false}
              data={data}
            />
          </Route>
          <Route exact path="/credits">
            <DashboardHeader isPdl={isPdl} logout={setloggedin} />
            <CreditManagement
              companyid={companyid}
              momatch_backend_url={momatch_backend_url}
            />
          </Route>
          <Route exact path="/postjob">
            {loggedin ? "" : <Redirect to="/" />}
            <DashboardHeader isPdl={isPdl} logout={setloggedin} />
            <JobPostForm
              backend_url={backend_url}
              companyid={companyid}
              postjobinitialvalues={postjobinitialvalues}
              editjob={false}
              token={token}
              setjobid={setjobid}
              setjobcode={setjobcode}
              momatch_backend_url={momatch_backend_url}
            />
          </Route>
          <Route exact path="/Candidates">
            <DashboardHeader isPdl={isPdl} logout={setloggedin} />
            <Candidates
              setmaildata={setmaildata}
              maildata={maildata}
              setjobcode={setjobcode}
              jobcode={jobcode}
              jobid={jobid}
              setjobid={setjobid}
              companyid={companyid}
              mo_backend_url={momatch_backend_url}
              backend_url={backend_url}
            />
          </Route>
          <Route exact path="/EditJob">
            {loggedin ? "" : <Redirect to="/" />}
            <DashboardHeader isPdl={isPdl} logout={setloggedin} />
            <EditJob
              backend_url={backend_url}
              momatch_backend_url={momatch_backend_url}
              setjobid={setjobid}
              companyid={companyid}
              jobid={jobid}
            />
          </Route>
          <Route exact path="/Settings">
            <DashboardHeader isPdl={isPdl} logout={setloggedin} />
            <Settings
              backend_url={backend_url}
              momatch_backend_url={momatch_backend_url}
              companyid={companyid}
              isPdl={isPdl}
              setlogin={setloggedin}
            />
          </Route>
          <Route exact path="/forgot">
            <Header />
            <ResetEmail mo_backend_url={momatch_backend_url} />
            <Footer />
          </Route>
          <Route path="/reset">
            <Header />
            <ResetPass mo_backend_url={momatch_backend_url} />
            <Footer />
          </Route>
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
