import React, { useState } from "react";
import "../../styles/setting.scss";
import { Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import "tachyons";
import TextField from "@mui/material/TextField";
import { useErrorHandler } from 'react-error-boundary'

export default function EmailtoReset({ mo_backend_url }) {
  const handleError = useErrorHandler()

  const [emailfound, setemailfound] = useState(null);
  const [email, setemail] = useState("");
  const [msg, setmsg] = useState("");

  const handleEmailChange = (event) => {
    setemail(event.target.value.toLowerCase());
  };

  const handleRequest = () => {
    fetch(`${mo_backend_url}/forgot/password`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setmsg(
            "Email sent, please check your email id to reset your password",
          );
          setemailfound(true);
        } else {
          setemailfound(false);
          setmsg("Email id is not linked with any account. Please try again");
        }
      })
      .catch((err) => {
        handleError(err)
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleRequest();
    }
  };

  return (
    <div className="w-100 vh-75" style={{ background: "#eef2f5" }}>
      <div className="center br2 mv5 tc bg-white ma2 w-60-l w-80-m w-90 pa4-l pa4-m pa3">
        <p className="gray tc f4-l f5-m f6">
          Provide your email address to send a password reset link
        </p>
        <div>
          <div className="creds mt3 w-100">
            <TextField
              name="mail"
              label="E-mail"
              value={email}
              onChange={(e) => handleEmailChange(e)}
              onKeyDown={(e) => handleKeyDown(e)}
              variant="outlined"
              className={`w-50-l w-70-m w-80`}
            />
          </div>
          <div className="mt4">
            <Button
              onClick={() => handleRequest()}
              className="dim"
              variant="outlined"
              style={{ borderColor: "#6EB6FF", color: "#6EB6FF" }}
            >
              Send Email
            </Button>
          </div>
          <div className={`${emailfound !== null ? "" : "hide"} mt4`}>
            <Alert severity={`${emailfound ? "success" : "error"}`}>
              {msg}
            </Alert>
          </div>
        </div>
      </div>
    </div>
  );
}
